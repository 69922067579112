import React from 'react';
import { SwiperSlide } from 'swiper/react';
import styled from 'styled-components';

import { useMedia } from '@tager/web-core';

import { SliderWrapper } from '@/components/LandingWidgets';

import { VideoSliderProps } from './types';
import Video from './components/Video';

export function VideoSlider({ id, videos }: VideoSliderProps) {
  const isTablet = useMedia('(max-width: 1259.9px)');

  if (!videos || videos.length === 0) {
    return null;
  }

  const slidesPerView = !isTablet ? 3 : 1;
  const isWithoutNavs = !isTablet ? videos.length <= 3 : videos.length === 1;

  return (
    <Component>
      <SliderWrapper
        id={id}
        options={{
          slidesPerView,
          spaceBetween: 20,
          noSwipingClass: 'swiper-no-swiping',
          withoutPagination: true,
          withoutNavs: isWithoutNavs,
        }}
      >
        {videos.map(({ link }, index) => (
          <SwiperSlide key={index} className="swiper-no-swiping">
            <Video link={link ?? ''} />
          </SwiperSlide>
        ))}
      </SliderWrapper>
    </Component>
  );
}

const Component = styled.div`
  .swiper-container {
    pointer-events: none;

    .swiper-slide {
      pointer-events: auto;
    }
  }
`;
