import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import Picture from '@/components/Picture';
import { CatalogCardSliderProps } from '@/components/CatalogCarCard/CatalogCarCard.types';
import Link from '@/components/Link';
import { colors } from '@/constants/theme';

function CatalogCardSlider({
  images,
  view,
  link,
  alt,
}: CatalogCardSliderProps) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <Component
      view={view}
      onMouseOut={() => {
        setActiveSlideIndex(0);
      }}
    >
      <SlideList to={link}>
        {images && images.length > 0
          ? images.slice(0, 4).map((item, index) => {
              return (
                <SlideListItem
                  key={index}
                  onMouseEnter={() => {
                    setActiveSlideIndex(index);
                  }}
                  isActive={activeSlideIndex === index}
                >
                  <StyledPicture
                    src={item}
                    loading="lazy"
                    alt={`${alt ?? 'catalog card image'} #${index + 1}`}
                  />
                </SlideListItem>
              );
            })
          : null}
      </SlideList>
      {images.length > 1 && (
        <PaginationList>
          {images.slice(0, 4).map((item: any, index: number) => {
            return (
              <PaginationListItem
                key={index}
                isActive={activeSlideIndex === index}
              />
            );
          })}
        </PaginationList>
      )}
    </Component>
  );
}

export default CatalogCardSlider;

const Component = styled.div<{ view?: 'landscape' | 'portrait' }>`
  position: relative;
  background-color: #efefef;
  padding-top: 60%;
  cursor: pointer;
  ${({ view }) =>
    view === 'portrait'
      ? css`
          border-radius: 8px 8px 0 0;
        `
      : css`
          border-radius: 8px 0 0 8px;
        `}
`;
const SlideList = styled(Link)`
  position: absolute !important;
  left: 0;
  top: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  display: flex;
`;
const SlideListItem = styled.div<{ isActive: boolean }>`
  flex-grow: 1;
  pointer-events: visible;

  ${({ isActive }) =>
    isActive
      ? css`
          ${StyledPicture} {
            opacity: 1;
            z-index: -1;
          }
        `
      : null}
  &:hover {
    picture,
    img {
      &:before {
        opacity: 1;
      }
    }
  }

  &:last-child {
    cursor: pointer;
  }
`;
const StyledPicture = styled(Picture)`
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  pointer-events: none;
  opacity: 0;
  border-radius: 8px 8px 0 0;

  picture,
  img {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: contain;
  }
`;

export const PaginationList = styled.div`
  z-index: 21;
  display: flex;
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
`;

export const PaginationListItem = styled.div<{ isActive: boolean }>`
  content: '';
  width: 40px;
  height: 4px;
  background-color: ${colors.black};
  border-radius: 3px;
  
  &:not(:last-child) {
    margin-right: 5px;
  }
  
  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${colors.white};
    `}
  }
`;
