import React from 'react';
import styled from 'styled-components';
import { SwiperSlide } from 'swiper/react';

import { useMedia } from '@tager/web-core';

import { SliderWrapper } from '@/components/LandingWidgets';
import StockCarCard from '@/components/StockCarCard';
import { ButtonNextLink } from '@/components/Button';

import { CarsStockListProps } from './types';
import ShowMoreCard from './components/ShowMoreCard';

export function CarsStockList({
  id,
  cars,
  image,
  buttonTitle,
  buttonLink,
}: CarsStockListProps) {
  const isLaptop = useMedia('(max-width: 1540px)');
  const isTablet = useMedia('(max-width: 1259px)');
  const isTabletSmall = useMedia('(max-width: 1023px)');
  const isMobile = useMedia('(max-width: 767px)');

  if (!cars || cars.length === 0) {
    return null;
  }

  const slidesPerView = !isTablet ? 4 : !isTabletSmall ? 3 : !isMobile ? 2 : 1;
  const spaceBetween = 12;
  const isWithoutNavs = !isTablet
    ? cars.length <= 4
    : !isTabletSmall
    ? cars.length <= 3
    : !isMobile
    ? cars.length <= 2
    : cars.length === 1;
  const isNoSwiping = !isTablet
    ? cars.length <= 4
    : !isTabletSmall
    ? cars.length <= 3
    : !isMobile
    ? cars.length <= 2
    : cars.length === 1;

  return (
    <Component>
      <SliderWrapper
        id={id}
        options={{
          slidesPerView,
          spaceBetween,
          noSwipingClass: 'swiper-no-swiping',
          withoutNavs: isWithoutNavs,
          withoutPagination: true,
        }}
      >
        {cars.map((car, index) => (
          <SwiperSlide
            key={index}
            className={isNoSwiping ? 'swiper-no-swiping' : ''}
          >
            <StockCarCard {...car} isLaptop={isLaptop} isMobile={isTablet} />
          </SwiperSlide>
        ))}

        {!isWithoutNavs && image && (
          <SwiperSlide>
            <ShowMoreCard
              image={image}
              buttonTitle={buttonTitle}
              buttonLink={buttonLink}
            />
          </SwiperSlide>
        )}
      </SliderWrapper>

      {!isWithoutNavs && (
        <ButtonWrapper>
          <StyledButtonLink variant="outlined" to={buttonLink}>
            {buttonTitle}
          </StyledButtonLink>
        </ButtonWrapper>
      )}
    </Component>
  );
}

const Component = styled.div`
  .swiper-slide {
    height: auto;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const StyledButtonLink = styled(ButtonNextLink)`
  max-width: 300px;
`;
