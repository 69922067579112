import { Nullable } from '@tager/web-core';
import {
  SettingsItemGallery,
  SettingsItemImage,
  SettingsItemString,
  ThumbnailType,
} from '@tager/web-modules';
import { SettingsItemCommon } from '@tager/web-modules/src/typings/models';

import { CarsStockType } from '@/services/stock/typings';
import { CarsType } from '@/typings/common';

export interface SettingsItemBoolean<Key extends string>
  extends SettingsItemCommon<Key> {
  value: boolean;
}

export interface SettingsItemStringArray<Key extends string>
  extends SettingsItemCommon<Key> {
  value: string[];
}

export interface SettingsItemDisclaimerFooterText<Key extends string>
  extends SettingsItemCommon<Key> {
  value: Array<{
    subdomain: string;
    text: string;
  }>;
}

export interface SettingsItemHeaderDealersWebModels<Key extends string>
  extends SettingsItemCommon<Key> {
  value: Array<{
    image: ThumbnailType;
    name: Nullable<string>;
    description: Nullable<string>;
    price: Nullable<string>;
    link: Nullable<string>;
  }>;
}

export interface SettingsItemHeaderBrands<Key extends string>
  extends SettingsItemCommon<Key> {
  value: Array<{
    image: ThumbnailType;
    name: Nullable<string>;
    link: Nullable<string>;
  }>;
}

export interface SettingsItemHelperModalGoogleEvents<Key extends string>
  extends SettingsItemCommon<Key> {
  value: Array<{
    googleEvent: Nullable<string>;
  }>;
}

export interface SettingsItemHelperCardGoogleEvents<Key extends string>
  extends SettingsItemCommon<Key> {
  value: Array<{
    googleEvent: Nullable<string>;
  }>;
}

export interface SettingsItemDealerWebPhones<Key extends string>
  extends SettingsItemCommon<Key> {
  value: Nullable<Array<{ phone: Nullable<string> }>>;
}

export interface SettingsItemDealerWebEmails<Key extends string>
  extends SettingsItemCommon<Key> {
  value: Nullable<Array<{ email: Nullable<string> }>>;
}

export interface SettingsItemMobileAppBlockStores<Key extends string>
  extends SettingsItemCommon<Key> {
  value: Array<{ icon: ThumbnailType; url: Nullable<string> }>;
}

export interface SettingsItemCatalogLinks<Key extends string>
  extends SettingsItemCommon<Key> {
  value: Array<{
    title: Nullable<string>;
    links: Array<{
      title: Nullable<string>;
      url: Nullable<string>;
    }>;
  }>;
}

export interface SettingsItemPurchaseLinks<Key extends string>
  extends SettingsItemCommon<Key> {
  value: Array<{
    title: Nullable<string>;
    url: Nullable<string>;
    isNewPage: boolean;
  }>;
}

export interface SettingsItemPurchaseDeliverySteps<Key extends string>
  extends SettingsItemCommon<Key> {
  value: Array<{ text: Nullable<string> }>;
}

export interface SettingsItemPurchaseDeliveryCostItems<Key extends string>
  extends SettingsItemCommon<Key> {
  value: Array<{ distance: Nullable<string>; price: Nullable<string> }>;
}

export type SettingsItemType =
  | SettingsItemHeaderBrands<'HEADER_BRANDS'>
  | SettingsItemString<'FOOTER_DISCLAIMER'>
  | SettingsItemString<'FOOTER_COPYRIGHT'>
  | SettingsItemGallery<'FOOTER_LOGOS'>
  | SettingsItemString<'FOOTER_DEALER_WEB_TOP_TEXT_PRIMARY'>
  | SettingsItemDealerWebPhones<'FOOTER_DEALER_WEB_PHONES'>
  | SettingsItemDealerWebEmails<'FOOTER_DEALER_WEB_EMAILS'>
  | SettingsItemString<'FOOTER_DEALER_WEB_INSTAGRAM'>
  | SettingsItemString<'FOOTER_DEALER_WEB_FACEBOOK'>
  | SettingsItemString<'FOOTER_DEALER_WEB_VK'>
  | SettingsItemString<'FOOTER_DEALER_WEB_APPSTORE'>
  | SettingsItemString<'FOOTER_DEALER_WEB_GOOGLE_PLAY'>
  | SettingsItemString<'FOOTER_DEALER_WEB_TOP_TEXT_SECONDARY'>
  | SettingsItemString<'FOOTER_DEALER_WEB_BOTTOM_TEXT'>
  | SettingsItemString<'FOOTER_DEALER_WEB_COPYRIGHT'>
  | SettingsItemString<'SOCIAL_FACEBOOK'>
  | SettingsItemString<'SOCIAL_YOUTUBE'>
  | SettingsItemString<'SOCIAL_ODNOKLASSNIKI'>
  | SettingsItemString<'SOCIAL_TWITTER'>
  | SettingsItemString<'SOCIAL_INSTAGRAM'>
  | SettingsItemString<'SOCIAL_VKONTAKTE'>
  | SettingsItemString<'MOBILE_APPSTORE_URL'>
  | SettingsItemString<'MOBILE_GOOGLE_PLAY_URL'>
  | SettingsItemString<'ORGANIZATION_NAME'>
  | SettingsItemString<'MAIN_ADDRESS'>
  | SettingsItemString<'PHONE_COMMON'>
  | SettingsItemString<'PHONE_AMP'>
  | SettingsItemString<'EMAIL'>
  | SettingsItemString<'PHONE_CALLBACK_GOOGLE_EVENT'>
  | SettingsItemString<'PHONE_CLICK_GOOGLE_EVENT'>
  | SettingsItemString<'FOOTER_CHECKOUT_TITLE'>
  | SettingsItemString<'FOOTER_CHECKOUT_DISCLAIMER'>
  | SettingsItemDisclaimerFooterText<'FOOTER_CHECKOUT_DISCLAIMER_SUBDOMAINS'>
  | SettingsItemGallery<'FOOTER_CHECKOUT_PAYMENT_LOGOS'>
  | SettingsItemString<'PURCHASE_DELIVERY_TITLE'>
  | SettingsItemPurchaseDeliverySteps<'PURCHASE_DELIVERY_STEPS'>
  | SettingsItemString<'PURCHASE_DELIVERY_COST_TITLE'>
  | SettingsItemPurchaseDeliveryCostItems<'PURCHASE_DELIVERY_COST_ITEMS'>
  | SettingsItemString<'PURCHASE_DELIVERY_NOTE'>
  | SettingsItemString<'PURCHASE_PASSPORT_INFO'>
  | SettingsItemString<'PURCHASE_PREPAYMENT_INFO'>
  | SettingsItemPurchaseLinks<'PURCHASE_LINKS'>
  | SettingsItemString<'PURCHASE_NOTFOUND_TEXT'>
  | SettingsItemString<'PURCHASE_NOTFOUND_BUTTON_TITLE'>
  | SettingsItemString<'PURCHASE_NOTFOUND_BUTTON_LINK'>
  | SettingsItemString<'PURCHASE_ORDER_CANCELLED_TEXT'>
  | SettingsItemBoolean<'PURCHASE_DISABLED'>
  | SettingsItemString<'PURCHASE_SPECIALIST_GOOGLE_EVENT'>
  | SettingsItemString<'СREDIT_NOTFOUND_TEXT'>
  | SettingsItemString<'СREDIT_NOTFOUND_BUTTON_TITLE'>
  | SettingsItemString<'СREDIT_NOTFOUND_BUTTON_LINK'>
  | SettingsItemString<'СREDIT_CALCULATION_TEXT'>
  | SettingsItemString<'CREDIT_APPLICATION_CREDIT_GOOGLE_EVENT'>
  | SettingsItemString<'CREDIT_APPLICATION_LEASING_GOOGLE_EVENT'>
  | SettingsItemString<'CREDIT_PREAPPROVAL_LEASING_GOOGLE_EVENT'>
  | SettingsItemString<'CONTACTS_AGREEMENT_HTML'>
  | SettingsItemBoolean<'CATALOG_DISABLED'>
  | SettingsItemString<'CATALOG_POPULAR_TITLE'>
  | SettingsItemCatalogLinks<'CATALOG_POPULAR_CATEGORIES'>
  | SettingsItemString<'CATALOG_CAR_PRICE_INFO'>
  | SettingsItemString<'CATALOG_MAIN_GOOGLE_EVENT'>
  | SettingsItemString<'CATALOG_MODEL_PAGE_GOOGLE_EVENT'>
  | SettingsItemString<'CATALOG_COMPARE_MODELS_PAGE_GOOGLE_EVENT'>
  | SettingsItemString<'PERSONAL_CABINET_BONUSES_TEXT'>
  | SettingsItemString<'HELPER_DIE_TEXT'>
  | SettingsItemImage<'HELPER_DIE_IMAGE_ON'>
  | SettingsItemImage<'HELPER_DIE_IMAGE_OFF'>
  | SettingsItemString<'HELPER_MODAL_TEXT'>
  | SettingsItemImage<'HELPER_MODAL_IMAGE'>
  | SettingsItemHelperModalGoogleEvents<'HELPER_MODAL_GOOGLE_EVENTS'>
  | SettingsItemGallery<'HELPER_CARD_IMAGES'>
  | SettingsItemHelperCardGoogleEvents<'HELPER_CARD_GOOGLE_EVENTS'>
  | SettingsItemString<'HELP_ON_THE_ROAD_TEXT'>
  | SettingsItemString<'HELP_ON_THE_ROAD_AGREEMENT_TEXT'>
  | SettingsItemString<'MOBILE_APP_BLOCK_TITLE'>
  | SettingsItemString<'MOBILE_APP_BLOCK_CONTENT'>
  | SettingsItemImage<'MOBILE_APP_BLOCK_IMAGE'>
  | SettingsItemMobileAppBlockStores<'MOBILE_APP_BLOCK_STORES'>
  | SettingsItemString<'CAR_STOCK_COMPARE_TEXT'>
  | SettingsItemString<'CAR_STOCK_COMPARE_NO_CARS'>
  | SettingsItemString<'NOT_FOUND_PAGE_ERROR'>
  | SettingsItemString<'NOT_FOUND_PAGE_TITLE'>
  | SettingsItemString<'NOT_FOUND_PAGE_SUBTITLE'>
  | SettingsItemString<'NOT_FOUND_PAGE_BUTTON'>
  | SettingsItemStringArray<'STOCK_NEW_DEALERS'>
  | SettingsItemString<'STOCK_ALL_INITIAL_SORT'>
  | SettingsItemString<'STOCK_AMP_INITIAL_SORT'>
  | SettingsItemString<'STOCK_NEW_INITIAL_SORT'>
  | SettingsItemString<'STOCK_ELECTRIC_INITIAL_SORT'>
  | SettingsItemString<'STOCK_COMMERCIAL_INITIAL_SORT'>
  | SettingsItemBoolean<'STOCK_ALL_ENABLED'>
  | SettingsItemBoolean<'STOCK_NEW_ENABLED'>
  | SettingsItemBoolean<'STOCK_AMP_ENABLED'>
  | SettingsItemBoolean<'STOCK_ELECTRIC_ENABLED'>
  | SettingsItemBoolean<'STOCK_COMMERCIAL_ENABLED'>
  | SettingsItemBoolean<'STOCK_AMP_PARTNER_CAR_ENABLED'>
  | SettingsItemStringArray<'STOCK_ALL_SORT_LIST'>
  | SettingsItemStringArray<'STOCK_NEW_SORT_LIST'>
  | SettingsItemStringArray<'STOCK_AMP_SORT_LIST'>
  | SettingsItemStringArray<'STOCK_ELECTRIC_SORT_LIST'>
  | SettingsItemStringArray<'STOCK_COMMERCIAL_SORT_LIST'>
  | SettingsItemStringArray<'STOCK_NEW_BRANDS'>
  | SettingsItemStringArray<'STOCK_NEW_MODELS'>
  | SettingsItemString<'STOCK_NEW_APPLICATION_GOOGLE_EVENT'>
  | SettingsItemString<'STOCK_AMP_APPLICATION_GOOGLE_EVENT'>
  | SettingsItemBoolean<'STOCK_NOINDEX_FILTER_PAGES'>
  | SettingsItemBoolean<'DETAILED_CAR_NEW_MODEL_BANNER_ENABLED'>
  | SettingsItemBoolean<'DETAILED_CAR_NEW_LEASING_FORM_ENABLED'>
  | SettingsItemString<'DETAILED_CAR_NEW_APPLICATION_GOOGLE_EVENT'>
  | SettingsItemString<'DETAILED_CAR_NEW_TEST_DRIVE_GOOGLE_EVENT'>
  | SettingsItemString<'DETAILED_CAR_NEW_CONSULTATION_GOOGLE_EVENT'>
  | SettingsItemString<'DETAILED_CAR_AMP_APPLICATION_GOOGLE_EVENT'>
  | SettingsItemString<'DETAILED_CAR_AMP_TEST_DRIVE_GOOGLE_EVENT'>
  | SettingsItemString<'DETAILED_CAR_AMP_MY_PRICE_GOOGLE_EVENT'>
  | SettingsItemString<'DETAILED_CAR_AMP_LEASING_PREAPPROVAL_GOOGLE_EVENT'>
  | SettingsItemBoolean<'DETAILED_CAR_NOINDEX'>
  | SettingsItemBoolean<'BLOG_DISABLED'>
  | SettingsItemBoolean<'HEADER_DEALER_WEB_HIDE_BUTTON_CALL'>
  | SettingsItemHeaderDealersWebModels<'HEADER_DEALER_WEB_MODELS'>;

export type StringField = Nullable<string>;

export interface TestDriveData {
  outdoorAvailable: boolean;
  locations: TestDriveLocation[];
}

export interface TestDriveLocation {
  id: number;
  dealerId: number;
  url: string;
  name: string;
  address: string;
  latitude: string;
  longitude: string;
  phone: string;
  image: string;
}

/** MapDealers **/
export type IAmpLocationsList = Array<IAmpLocationsCity>;

export interface IAmpLocationsCity {
  city: {
    id: number;
    name: string;
  };
  locations: Array<IAmpLocation>;
}

export interface IAmpLocation {
  id: number;
  latitude: number;
  longitude: number;
  address: string;
  workTime: string | null;
}

/** Profile **/
export interface ProfileCarModel {
  readonly brand?: string;
  readonly model?: string;
  readonly name?: string;
  readonly vin: string;
  readonly number?: string;
  readonly plate?: string;
  readonly mileage: number;
  readonly archive: boolean;
  readonly image: Nullable<string>;
}

export interface ProfileModel {
  firstName?: string;
  lastName?: string;
  percent?: number;
  cars: Nullable<Array<ProfileCarModel>>;
  hasCars: boolean;
  hasPurchases?: boolean;
  availability: Nullable<IAvailability>;
  bookmarks?: ICarsBookmarks[];
}

export interface IAvailability {
  bonuses: boolean;
  cars: boolean;
  purchasesCars: boolean;
  purchasesInsurances: boolean;
  purchasesOther: boolean;
  purchasesServices: boolean;
  requests: boolean;
}

export interface ICarsBookmarks {
  data: { type: StringField; id: number };
  type: StringField;
}

/** Dealers **/
export interface Dealer {
  id: number;
  dealerId: number;
  url: Nullable<string>;
  name: Nullable<string>;
  legalName: Nullable<string>;
  rating: number;
  reviewsCount: number;
  brands: DealerBrand[];
  locations: DealerLocation[];
  hideLogos: boolean;
}

export interface DealerBrand {
  id: number;
  logo: Nullable<string>;
  name: Nullable<string>;
}

export interface DealerLocation {
  id: number;
  name: Nullable<string>;
  address: Nullable<string>;
  phones: string[];
  websites: string[];
  latitude: Nullable<number>;
  longitude: Nullable<number>;
  socialYoutube: Nullable<string>;
  socialInstagram: Nullable<string>;
  socialOdnoklassnikiUrl: Nullable<string>;
  socialFacebookUrl: Nullable<string>;
  socialVkontakteUrl: Nullable<string>;
  socialTelegramUrl: Nullable<string>;
  socialViberUrl: Nullable<string>;
  socialSkypeUrl: Nullable<string>;
  divisions: Division[];
}

export interface Division {
  id: number;
  name: Nullable<string>;
  phones: string[];
  workTime: Nullable<string>;
}

export interface Marker {
  name: Nullable<string>;
  address: Nullable<string>;
  latitude?: number;
  longitude?: number;
}

export interface DealerReviews {
  total?: number;
  data: DealerReview[];
}

export interface DealerReview {
  date: Nullable<string>;
  name: Nullable<string>;
  like: Nullable<string>;
  dislike: Nullable<string>;
  answer: Nullable<string>;
  rating: number;
}

/** Blog **/
export interface BlogPageSeo {
  name: Nullable<string>;
  pageTitle: Nullable<string>;
  pageDescription: Nullable<string>;
  openGraphImage: Nullable<string>;
}

export interface BlogTag {
  tag: Nullable<string>;
  urlAlias: Nullable<string>;
}

export interface BlogPosts {
  total: number;
  items: BlogPost[];
}

export interface BlogPost {
  title: Nullable<string>;
  url: Nullable<string>;
  image: ThumbnailType;
}

export interface ServiceBlogPost {
  title: Nullable<string>;
  url: Nullable<string>;
  image: ThumbnailType;
  landscapeImage: ThumbnailType;
}

export enum BlogCategory {
  News = 'news',
  Offers = 'offers',
  Reviews = 'reviews',
  Brand = 'brand',
}

/** Blog Single Post **/
export interface BlogSinglePost {
  id: number;
  designVersion: 'new' | 'old' | 'dealerWeb';
  title: Nullable<string>;
  excerpt: Nullable<string>;
  datePublished: Nullable<string>;
  image: ThumbnailType;
  mobileImage: ThumbnailType;
  tags: BlogTag[];
  deadlineText: Nullable<string>;
  body: Nullable<string>;
  dealers: BlogPostDealer[];
  authorName: Nullable<string>;
  authorText: Nullable<string>;
  authorImage: ThumbnailType;
  ctaTitle: Nullable<string>;
  ctaButtons: CtaButton[];
  galleries: ImagesGallery[][];
  contents: string[][];
  vote: Vote;
  relatedPosts: RelatedPost[];
  pageTitle: Nullable<string>;
  pageDescription: Nullable<string>;
  openGraphImage: Nullable<string>;
}

export interface BlogPostDealer {
  id?: number;
  url: Nullable<string>;
  name: Nullable<string>;
  legalName: Nullable<string>;
  phones: Nullable<string[]>;
}

export interface CtaButton {
  label: Nullable<string>;
  mode: Nullable<string>;
  link: Nullable<string>;
}

export interface ImagesGallery {
  image: ThumbnailType;
  caption: Nullable<string>;
}

export interface Vote {
  question: Nullable<string>;
  options: VoteOption[];
}

export interface VoteOption {
  option: Nullable<string>;
  value: number;
}

export interface RelatedPost {
  title: Nullable<string>;
  url: Nullable<string>;
  image: ThumbnailType;
}

/** Last News **/
export interface LatestNews {
  id?: number;
  datePublished: string;
  title: string;
  url: string;
  image?: ThumbnailType;
}

/** Banners **/
export interface MainMenuBannersData {
  data: MainMenuBanner[];
}

export interface MainMenuBanner {
  link: string;
  openNewTab: boolean;
  image: { default: ThumbnailType };
  fields: BannerField[];
}

export interface BannerField {
  name: string;
  value: ThumbnailType;
}

export interface HomeBannersData {
  data: HomeBanner[];
}

export interface HomeBanner {
  link: string;
  openNewTab: boolean;
  image: {
    desktop: ThumbnailType;
    laptop: ThumbnailType;
    mobile?: ThumbnailType;
  };
  fields: BannerField[];
}

export interface DetailedCarBannerParams {
  type: CarsType;
  brand: string;
  model: string;
}

export interface DetailedCarBannerResponseType {
  banner: Nullable<{
    image: Nullable<ThumbnailType>;
    imageMobile: Nullable<ThumbnailType>;
    url: string;
    isNewTab: boolean;
  }>;
}

export interface CarsStockBannerParams {
  type: CarsStockType;
  brand?: string;
  model?: string;
}

export interface CarsStockBannerResponseType {
  desktop: Nullable<{
    row: Nullable<number>;
    size: Nullable<number>;
    image: Nullable<string>;
    imageLaptop: Nullable<string>;
    url: string;
    isNewTab: boolean;
  }>;
  mobile: Nullable<{
    position: Nullable<number>;
    image: Nullable<string>;
    url: string;
    isNewTab: boolean;
  }>;
}

/** Single Model **/
export interface ModelPage {
  generationId: number;
  pageTitle: Nullable<string>;
  pageDescription: Nullable<string>;
  configurationsPageTitle: string;
  configurationsPageDescription: string;
  openGraphImage: Nullable<string>;
}

/** SEO Templates **/
export type SEOTemplatesTypes =
  | 'catalog'
  | 'catalog_city'
  | 'catalog_brand'
  | 'catalog_brand_city'
  | 'catalog_brands'
  | 'catalog_filter_body'
  | 'catalog_filter_body_city'
  | 'catalog_filter_fuel'
  | 'catalog_model_page'
  | 'catalog_model_page_city'
  | 'stock_amp'
  | 'stock_amp_name'
  | 'stock_amp_body'
  | 'stock_amp_year'
  | 'stock_amp_gearbox'
  | 'stock_amp_fuel'
  | 'stock_new'
  | 'stock_new_name'
  | 'stock_new_body'
  | 'stock_new_gearbox'
  | 'stock_new_fuel'
  | 'stock_electric'
  | 'stock_electric_name'
  | 'stock_electric_body'
  | 'stock_commercial'
  | 'stock_commercial_name'
  | 'stock_commercial_body'
  | 'stock_commercial_gearbox'
  | 'stock_commercial_fuel'
  | 'stock_all'
  | 'stock_all_name'
  | 'stock_all_body'
  | 'stock_all_gearbox'
  | 'stock_all_fuel'
  | 'stock_new_detailed'
  | 'stock_amp_detailed'
  | 'service_single'
  | 'service_brand'
  | 'service_brand_service'
  | 'dealers_single'
  | 'offers_blog_single'
  | 'news_blog_single'
  | 'reviews_blog_single'
  | 'brand_blog_single';

export interface SEOTemplate {
  title: Nullable<string>;
  description: Nullable<string>;
  h1: Nullable<string>;
  keywords: Nullable<string>;
  openGraphImage: Nullable<string>;
}

/** Rate car **/
export interface RateCarOnlineParams {
  brand: string;
  model: string;
  year: number;
  body: string;
  generation: string;
  fuel: string;
  gearbox: string;
  enginePower: string;
  engineVolume: string;
  images: string[];
  name: string;
  phone: string;
  email: string;
}
