import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

import { colors as componentColors } from './constants';
import { SloganProps } from './types';

export function Slogan({ text }: SloganProps) {
  return <Component dangerouslySetInnerHTML={{ __html: text }} />;
}

export const Component = styled.div`
  position: relative;
  padding: 30px 50px;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  background: ${componentColors.background};
  border: 1px solid ${componentColors.borders};
  color: ${componentColors.text};

  ${media.tablet(css`
    padding: 20px;
    font-size: 16px;
    line-height: 21px;
  `)}

  b,
  strong {
    font-weight: 700;
    color: ${componentColors.selectedText};
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 8px;
    background: ${componentColors.line};
  }
`;
