import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';
import Button, { ButtonNextLink } from '@/components/Button';

import { colors as componentColors } from './constants';
import { ModelCardProps } from './types';

export function ModelCard({
  image,
  imageMobile,
  name,
  price,
  description,
  specifications,
  preorderText,
  onPreorder,
  link,
  isNewTabLink,
  isInStock,
  isReverse,
}: ModelCardProps) {
  return (
    <Component isReverse={isReverse}>
      <Image
        loading="lazy"
        desktop={convertThumbnailToPictureImage(image)}
        laptop={convertThumbnailToPictureImage(image)}
        tabletSmall={convertThumbnailToPictureImage(imageMobile ?? image)}
        mobile={convertThumbnailToPictureImage(imageMobile ?? image)}
        alt={name}
      />

      <Info>
        <Name>{name}</Name>
        {price && <Price>{price}</Price>}
        {isInStock && <Status>В наличии</Status>}
        <Description>{description}</Description>
        <Specifications>
          {specifications &&
            specifications.length !== 0 &&
            specifications.map((item, index) => {
              return (
                <Specification key={index}>
                  <SpecificationLabel>{item.label}</SpecificationLabel>
                  <SpecificationValue>{item.value}</SpecificationValue>
                </Specification>
              );
            })}
        </Specifications>

        <ButtonsWrapper>
          <StyledButton variant="contained" onClick={onPreorder}>
            {preorderText}
          </StyledButton>
          {link && (
            <StyledButtonLink
              variant="outlined"
              to={link}
              target={isNewTabLink ? '_blank' : '_self'}
            >
              Подробнее о модели
            </StyledButtonLink>
          )}
        </ButtonsWrapper>
      </Info>
    </Component>
  );
}

export const Component = styled.div<{ isReverse?: boolean }>`
  display: flex;

  ${media.tablet(css`
    flex-direction: column;
  `)}

  ${({ isReverse }) =>
    isReverse &&
    css`
      flex-direction: row-reverse;
    `}
`;

const Image = styled(Picture)`
  flex: 1 1 50%;
  max-width: 50%;
  padding-top: 37.821%;

  ${media.tablet(css`
    flex: auto;
    max-width: none;
    padding-top: 50.821%;
  `)}

  ${media.mobile(css`
    padding-top: 58.335%;
  `)}

  picture,
  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const Info = styled.div`
  flex: 1 1 50%;
  max-width: 50%;
  padding: 40px 62px;
  background: ${componentColors.infoBackground};

  ${media.tablet(css`
    flex: auto;
    max-width: none;
    padding: 30px 20px;
  `)}
`;

const Name = styled.p`
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  color: ${componentColors.textSecondary};

  ${media.tablet(css`
    font-size: 20px;
    line-height: 26px;
  `)}
`;

const Price = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  margin-top: 20px;
  color: ${colors.main};

  ${media.tablet(css`
    font-size: 16px;
    line-height: 21px;
    margin-top: 5px;
  `)}
`;

const Status = styled.p`
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  margin-top: 10px;
  color: ${colors.main};

  ${media.tablet(css`
    font-size: 16px;
    line-height: 21px;
  `)}
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 21px;
  margin-top: 20px;
  color: ${componentColors.textPrimary};

  ${media.tablet(css`
    margin-top: 10px;
  `)}

  ${media.mobile(css`
    display: none;
  `)}
`;

const Specifications = styled.div`
  margin-top: 20px;
`;

const Specification = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid ${componentColors.line};

  &:not(:first-child) {
    margin-top: 8px;
  }
`;

const SpecificationLabel = styled.p`
  font-size: 16px;
  line-height: 21px;
  color: ${componentColors.textPrimary};
`;

const SpecificationValue = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: right;
  margin-left: 10px;
  color: ${componentColors.textSecondary};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 40px;

  ${media.tablet(css`
    flex-direction: column;
    margin-top: 20px;
  `)}
`;

const StyledButton = styled(Button)`
  max-width: 230px;
  padding: 14px 0;

  ${media.tablet(css`
    max-width: none;
  `)}
`;

const StyledButtonLink = styled(ButtonNextLink)`
  max-width: 240px;
  padding: 14px 0;
  margin-left: 20px;

  ${media.tablet(css`
    max-width: none;
    margin-left: 0;
    margin-top: 10px;
  `)}
`;
