import React from 'react';
import GoogleMapReact from 'google-map-react';

import { useMedia } from '@tager/web-core';

import { getMapProps } from '../../helpers';

import { GoogleMapProps } from './types';
import { getMapStyles } from './helpers';
import Marker from './components/Marker';

function GoogleMap({ markers }: GoogleMapProps) {
  const isTablet = useMedia('(max-width: 1259px)');

  if (!markers || markers.length === 0) {
    return null;
  }

  const mapStyles = getMapStyles();
  const points = markers.map(({ latitude, longitude }) => ({
    latitude,
    longitude,
  }));
  const mapProps = getMapProps(points);

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: process.env.NEXT_PUBLIC_API_KEY_GOOGLE_MAP ?? '',
      }}
      center={!isTablet ? mapProps.desktop.center : mapProps.mobile.center}
      defaultZoom={!isTablet ? mapProps.desktop.zoom : mapProps.mobile.zoom}
      options={{
        styles: mapStyles,
      }}
    >
      {markers.map(({ address, name, latitude, longitude }, index) => (
        <Marker
          key={index}
          address={address}
          name={name}
          lat={latitude}
          lng={longitude}
        />
      ))}
    </GoogleMapReact>
  );
}

export default GoogleMap;
