import React, { useState } from 'react';
import { Swiper } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import styled, { css } from 'styled-components';
import { uniqueId } from 'lodash';

import { useUpdateEffect } from '@tager/web-core';

import { getTheme, ThemeValuesType } from '@/constants/theme';
import { media } from '@/utils/mixin';
import ArrowIcon from '@/assets/svg/arrow-right-40px.svg';

import { colors as componentColors } from './constants';
import { SliderWrapperProps } from './types';

SwiperCore.use([Navigation, Pagination, Autoplay]);

export function SliderWrapper({ id, options, children }: SliderWrapperProps) {
  const [key, setKey] = useState('');
  const theme = getTheme();
  const paginationClassname = `swiper-pagination${id ?? ''}`;
  const navPrevClassname = `swiper-prev${id ?? ''}`;
  const navNextClassname = `swiper-next${id ?? ''}`;

  useUpdateEffect(() => {
    setKey(uniqueId());
  }, [options]);

  return (
    <Component withoutPagination={options?.withoutPagination}>
      {!options?.withoutNavs && (
        <Header>
          <NavButtons>
            <NavButton className={navPrevClassname} theme={theme} idNumber={id}>
              <ArrowIcon />
            </NavButton>
            <NavButton className={navNextClassname} theme={theme} idNumber={id}>
              <ArrowIcon />
            </NavButton>
          </NavButtons>
        </Header>
      )}

      <Swiper
        key={key}
        slidesPerView={options?.slidesPerView ?? 'auto'}
        spaceBetween={options?.spaceBetween ?? 0}
        speed={600}
        autoplay={options?.autoplay}
        navigation={{
          prevEl: `.${navPrevClassname}`,
          nextEl: `.${navNextClassname}`,
        }}
        pagination={{
          el: `.${paginationClassname}`,
          type: 'bullets',
          clickable: true,
        }}
        noSwipingClass={options?.noSwipingClass}
      >
        {children}
      </Swiper>

      {!options?.withoutPagination && (
        <PaginationWrapper className={paginationClassname} theme={theme} />
      )}
    </Component>
  );
}

export const Component = styled.div<{ withoutPagination?: boolean }>`
  position: relative;

  ${({ withoutPagination }) =>
    !withoutPagination &&
    css`
      padding-bottom: 30px;

      ${media.tablet(
        css`
          padding-bottom: 20px;
        `
      )}
    `}
`;

export const Header = styled.div`
  position: absolute;
  right: 0;
  top: -60px;

  ${media.tablet(css`
    top: -50px;
  `)}
`;

export const NavButtons = styled.div`
  display: flex;
`;

export const NavButton = styled.button<{
  theme: ThemeValuesType;
  idNumber?: number;
}>`
  z-index: 2;
  width: 40px;
  height: 40px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${componentColors.navActiveBackground};
  border-radius: 50%;
  border: 1px solid ${componentColors.navActiveBorders};

  svg {
    width: 15px;
    height: 15px;
    fill: ${componentColors.navActiveIcon};

    ${media.tablet(css`
      width: 12px;
      height: 12px;
    `)}
  }

  ${media.tablet(css`
    width: 30px;
    height: 30px;
  `)}

  ${({ theme }) =>
    theme === 'hisun' &&
    css`
      border-radius: 0;
    `}

  &.swiper-prev${({ idNumber }) => idNumber ?? ''} {
    svg {
      transform: rotate(180deg);
    }
  }

  &.swiper-next${({ idNumber }) => idNumber ?? ''} {
    margin-left: 10px;

    ${media.tablet(css`
      margin-left: 5px;
    `)}
  }

  &.swiper-button-disabled {
    background: ${componentColors.navDisactiveBackground};
    border: 1px solid ${componentColors.navDisactiveBorders};

    svg {
      fill: ${componentColors.navDisactiveIcon};
    }
  }
`;

export const PaginationWrapper = styled.div<{ theme: ThemeValuesType }>`
  z-index: 1;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 20px;

  .swiper-pagination-bullet {
    cursor: pointer;
    opacity: 1;
    width: 8px;
    height: 8px;
    background: ${componentColors.paginationDisactiveBackground};

    &:not(:first-child) {
      margin-left: 5px;
    }

    ${({ theme }) =>
      theme === 'hisun' &&
      css`
        border-radius: 0;
      `}
  }

  .swiper-pagination-bullet-active {
    background: ${componentColors.paginationActiveBackground};
    border: 1px solid ${componentColors.paginationActiveBorders};
  }
`;
