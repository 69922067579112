import { getTheme } from '@/constants/theme';

const colorsMain = {
  infoBackground: '#fff',
  textPrimary: '#6c7075',
  textSecondary: '#000',
  line: '#d3dadf',
} as const;

const colorsB2b = {
  infoBackground: '#fff',
  textPrimary: '#6c7075',
  textSecondary: '#182028',
  line: '#d3dadf',
} as const;

const colorsHisun = {
  infoBackground: '#f4f6f7',
  textPrimary: '#5c5c5c',
  textSecondary: '#333',
  line: '#d6d6d6',
} as const;

const colorsJac = {
  infoBackground: '#fff',
  textPrimary: '#636466',
  textSecondary: '#1d1d1b',
  line: '#d7d7d7',
} as const;

const colorsJetour = {
  infoBackground: '#fff',
  textPrimary: '#4c4c4c',
  textSecondary: '#000',
  line: '#e5e5e5',
} as const;

export const colors = (() => {
  switch (getTheme()) {
    case 'b2b':
      return colorsB2b;
    case 'hisun':
      return colorsHisun;
    case 'jac':
      return colorsJac;
    case 'jetour':
      return colorsJetour;
    default:
      return colorsMain;
  }
})();
