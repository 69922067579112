import { getTheme } from '@/constants/theme';

const colorsMain = {
  textPrimary: '#6c7075',
  textSecondary: '#000',
  social: '#93999e',
} as const;

const colorsB2b = {
  textPrimary: '#6c7075',
  textSecondary: '#182028',
  social: '#93999e',
} as const;

const colorsHisun = {
  textPrimary: '#5c5c5c',
  textSecondary: '#333',
  social: '#5c5c5c',
} as const;

const colorsJac = {
  textPrimary: '#636466',
  textSecondary: '#1d1d1b',
  social: '#949494',
} as const;

const colorsJetour = {
  textPrimary: '#4c4c4c',
  textSecondary: '#000',
  social: '#949494',
} as const;

export const colors = (() => {
  switch (getTheme()) {
    case 'b2b':
      return colorsB2b;
    case 'hisun':
      return colorsHisun;
    case 'jac':
      return colorsJac;
    case 'jetour':
      return colorsJetour;
    default:
      return colorsMain;
  }
})();
