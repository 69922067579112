import { getTheme } from '@/constants/theme';

const colorsMain = {
  line: '#0061ed',
  paginationActiveBackground: '#0061ed',
  paginationDisactiveBackground: '#d3dadf',
  paginationDisactiveMobileBackground: '#d3dadf',
  paginationActiveBorder: 'transparent',
  paginationActiveMobileBorder: 'transparent',
} as const;

const colorsB2b = {
  line: '#0066b3',
  paginationActiveBackground: '#0066b3',
  paginationDisactiveBackground: '#d3dadf',
  paginationDisactiveMobileBackground: '#d3dadf',
  paginationActiveBorder: 'transparent',
  paginationActiveMobileBorder: 'transparent',
} as const;

const colorsHisun = {
  line: '#ec1b23',
  paginationActiveBackground: 'transparent',
  paginationDisactiveBackground: '#fff',
  paginationDisactiveMobileBackground: '#333',
  paginationActiveBorder: '#fff',
  paginationActiveMobileBorder: '#670b22',
} as const;

const colorsJac = {
  line: '#cc000c',
  paginationActiveBackground: '#cc000c',
  paginationDisactiveBackground: '#fff',
  paginationDisactiveMobileBackground: '#d7d7d7',
  paginationActiveBorder: 'transparent',
  paginationActiveMobileBorder: 'transparent',
} as const;

const colorsJetour = {
  line: '#68a598',
  paginationActiveBackground: 'transparent',
  paginationDisactiveBackground: '#ff874a',
  paginationDisactiveMobileBackground: '#ff874a',
  paginationActiveBorder: '#ff874a',
  paginationActiveMobileBorder: '#ff874a',
} as const;

export const colors = (() => {
  switch (getTheme()) {
    case 'b2b':
      return colorsB2b;
    case 'hisun':
      return colorsHisun;
    case 'jac':
      return colorsJac;
    case 'jetour':
      return colorsJetour;
    default:
      return colorsMain;
  }
})();
