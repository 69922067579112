import { getTheme } from '@/constants/theme';

const colorsMain = {
  text: '#fff',
} as const;

const colorsB2b = {
  text: '#fff',
} as const;

const colorsHisun = {
  text: '#fff',
} as const;

const colorsJac = {
  text: '#1d1d1b',
} as const;

const colorsJetour = {
  text: '#000',
} as const;

export const colors = (() => {
  switch (getTheme()) {
    case 'b2b':
      return colorsB2b;
    case 'hisun':
      return colorsHisun;
    case 'jac':
      return colorsJac;
    case 'jetour':
      return colorsJetour;
    default:
      return colorsMain;
  }
})();
