import * as Yup from 'yup';

import { ServiceFormSecondaryFields } from './types';

export const initialServiceFormSecondaryValues: ServiceFormSecondaryFields = {
  name: '',
  phone: '',
  message: '',
};

export const validationServiceFormSecondarySchema: Yup.SchemaOf<ServiceFormSecondaryFields> =
  Yup.object().shape({
    name: Yup.string().required('Укажите имя'),
    phone: Yup.string().required('Укажите номер телефона'),
    message: Yup.string(),
  });
