import { getTheme } from '@/constants/theme';

const colorsMain = {
  paginationActiveBackground: '#0061ed',
  paginationActiveBorders: 'transparent',
  paginationDisactiveBackground: '#d3dadf',
  paginationDisactiveMobileBackground: '#d3dadf',
} as const;

const colorsB2b = {
  paginationActiveBackground: '#0066b3',
  paginationActiveBorders: 'transparent',
  paginationDisactiveBackground: '#d3dadf',
  paginationDisactiveMobileBackground: '#d3dadf',
} as const;

const colorsHisun = {
  paginationActiveBackground: '#670b22',
  paginationActiveBorders: 'transparent',
  paginationDisactiveBackground: '#d3dadf',
  paginationDisactiveMobileBackground: '#d3dadf',
} as const;

const colorsJac = {
  paginationActiveBackground: '#cc000c',
  paginationActiveBorders: 'transparent',
  paginationDisactiveBackground: '#fff',
  paginationDisactiveMobileBackground: '#d7d7d7',
} as const;

const colorsJetour = {
  paginationActiveBackground: 'transparent',
  paginationActiveBorders: '#ff874a',
  paginationDisactiveBackground: '#ff874a',
  paginationDisactiveMobileBackground: '#ff874a',
} as const;

export const colors = (() => {
  switch (getTheme()) {
    case 'b2b':
      return colorsB2b;
    case 'hisun':
      return colorsHisun;
    case 'jac':
      return colorsJac;
    case 'jetour':
      return colorsJetour;
    default:
      return colorsMain;
  }
})();
