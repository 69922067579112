import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

import { VideoProps } from './types';

function Video({ link }: VideoProps) {
  return (
    <Component>
      <VideoFrame
        src={link}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Component>
  );
}

export default Video;

const Component = styled.div`
  position: relative;
  padding-top: 60.001%;

  ${media.tablet(css`
    padding-top: 65.627%;
  `)}
`;

const VideoFrame = styled.iframe`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
`;
