export const defaultMapProps = {
  desktop: {
    center: {
      lat: 53.89,
      lng: 27.4,
    },
    zoom: 10,
  },
  mobile: {
    center: {
      lat: 53.95,
      lng: 27.5,
    },
    zoom: 10,
  },
};
