import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled, { css } from 'styled-components';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import Picture from '@/components/Picture';
import Link from '@/components/Link';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { NavButtonWrapper, NavigationButton } from '@/components/styles';
import NavArrow from '@/assets/svg/nav-arrow.svg';

import { colors as componentColors } from './constants';
import { BannersSliderPrimaryProps } from './types';

SwiperCore.use([Autoplay, Pagination, Navigation]);

export function BannersSliderPrimary({
  id,
  banners,
}: BannersSliderPrimaryProps) {
  if (!banners || banners.length === 0) {
    return null;
  }

  const isOneBanner = banners.length <= 1;
  const paginationClassname = `swiper-pagination${id ?? ''}`;

  return (
    <Component>
      <Container>
        <Swiper
          slidesPerView="auto"
          touchMoveStopPropagation={true}
          speed={1250}
          allowTouchMove={true}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          pagination={{
            el: `.${paginationClassname}`,
            type: 'bullets',
            clickable: true,
          }}
          navigation={{
            prevEl: `.swiper-prev`,
            nextEl: `.swiper-next`,
          }}
          noSwipingClass={'swiper-no-swiping'}
        >
          {banners.map(({ image, url, mobileImage, text }, index) => (
            <SwiperSlide
              key={index}
              className={isOneBanner ? 'swiper-no-swiping' : ''}
            >
              <SlideItem>
                <SlidePicture
                  loading="lazy"
                  desktop={convertThumbnailToPictureImage(image)}
                  laptop={convertThumbnailToPictureImage(image)}
                  tabletSmall={convertThumbnailToPictureImage(
                    mobileImage ?? image
                  )}
                  mobile={convertThumbnailToPictureImage(mobileImage ?? image)}
                  alt={text ? text : `banner image ${index + 1}`}
                />
                {text && <SliderText>{text}</SliderText>}
                {url && <SlideLink to={url} target="_blank" />}
              </SlideItem>
            </SwiperSlide>
          ))}

          {!isOneBanner && (
            <React.Fragment>
              <StyledNavButtonWrapper className="swiper-prev" prev>
                <NavigationButton prev>
                  <NavArrow />
                </NavigationButton>
              </StyledNavButtonWrapper>
              <StyledNavButtonWrapper className="swiper-next" next>
                <NavigationButton next>
                  <NavArrow />
                </NavigationButton>
              </StyledNavButtonWrapper>
            </React.Fragment>
          )}
        </Swiper>

        <PaginationWrapper className={paginationClassname} />
      </Container>
    </Component>
  );
}

const Component = styled.div`
  width: 100%;
  max-width: 1840px;
  margin: 0 auto;

  ${media.tablet(css`
    padding-bottom: 20px;
  `)}
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  padding-top: 29.033%;

  .swiper-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  ${media.tablet(css`
    padding-top: 41.668%;
  `)}
`;

const SlideItem = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

const SlidePicture = styled(Picture)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  img,
  picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const SlideLink = styled(Link)`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const SliderText = styled.h1`
  position: absolute;
  top: 50%;
  left: 150px;
  transform: translateY(-50%);
  max-width: calc(50% - 225px);
  font-weight: 700;
  font-size: 46px;
  line-height: 59px;
  margin: 0;
  color: ${colors.white};

  ${media.tablet(css`
    left: 20px;
    font-size: 4.44vw;
    line-height: 120%;
    max-width: 50%;
  `)}
`;

const PaginationWrapper = styled.div`
  z-index: 1;
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 20px;

  .swiper-pagination-bullet {
    cursor: pointer;
    opacity: 1;
    width: 8px;
    height: 8px;
    border: 1px solid ${componentColors.paginationActiveBorders};
    background: ${componentColors.paginationDisactiveBackground};

    &:not(:first-child) {
      margin-left: 5px;
    }

    ${media.tablet(css`
      background: ${componentColors.paginationDisactiveMobileBackground};
    `)}
  }

  .swiper-pagination-bullet-active {
    background: ${componentColors.paginationActiveBackground};
  }

  ${media.tablet(css`
    bottom: -20px;
  `)}
`;

const StyledNavButtonWrapper = styled(NavButtonWrapper)<{
  small?: boolean;
  next?: boolean;
}>`
  ${media.tablet(css`
    display: none;
  `)}

  ${({ next }) =>
    next &&
    css`
      padding-left: 7%;
    `}

  ${({ prev }) =>
    prev &&
    css`
      padding-right: 7%;
    `}
`;
