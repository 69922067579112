import React from 'react';
import styled, { css } from 'styled-components';

import Picture from '@/components/Picture';
import Link from '@/components/Link';
import { colors } from '@/constants/theme';
import Button from '@/components/Button';
import { media } from '@/utils/mixin';

import { CreditOfferCardProps } from './types';

export function CreditOfferCard({
  image,
  title,
  description,
  buttonText,
  link,
  badge,
}: CreditOfferCardProps) {
  return (
    <Component to={link}>
      <Image
        loading="lazy"
        src={image?.url}
        src2x={image?.url_2x}
        srcWebp={image?.url_webp}
        srcWebp2x={image?.url_webp_2x}
        alt={title}
      />

      <Content>
        <Title>{title}</Title>
        <Description dangerouslySetInnerHTML={{ __html: description }} />
        <ButtonWrapper>
          <StyledButton variant="contained">{buttonText}</StyledButton>
        </ButtonWrapper>
      </Content>

      {badge && <Badge>{badge}</Badge>}
    </Component>
  );
}

const Component = styled(Link)`
  position: relative;
  display: flex;

  ${media.tablet(css`
    flex-direction: column;
  `)}

  &:hover {
    background: ${colors.gray200};
  }
`;

const Image = styled(Picture)`
  flex: 1 1 40.477%;
  max-width: 40.477%;
  padding-top: 23.81%;

  ${media.tablet(css`
    flex: 1 1 auto;
    max-width: none;
    padding-top: 50.821%;
  `)}

  picture,
  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const Content = styled.div`
  flex: 1;
  max-width: 100%;
  padding: 60px;

  ${media.tablet(css`
    padding: 30px 20px;
  `)}
`;

const Title = styled.p`
  font-weight: 600;
  font-size: 35px;
  line-height: 45px;
  color: ${colors.black};

  ${media.tablet(css`
    font-size: 20px;
    line-height: 26px;
  `)}
`;

const Description = styled.div`
  font-size: 20px;
  line-height: 26px;
  margin-top: 20px;
  color: ${colors.gray500};

  ${media.tablet(css`
    font-size: 16px;
    line-height: 21px;
    margin-top: 10px;
  `)}

  a {
    color: ${colors.main};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  max-width: 200px;
  margin-top: 40px;

  ${media.tablet(css`
    max-width: none;
    margin-top: 30px;
  `)}
`;

const StyledButton = styled(Button)`
  pointer-events: none;
`;

const Badge = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 5px 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  border-radius: 20px;
  color: ${colors.white};
  background: ${colors.green300};
`;
