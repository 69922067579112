import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { useMedia } from '@tager/web-core';

import { SliderWrapper } from '@/components/LandingWidgets';

import { CreditSliderProps } from './types';
import CreditCard from './components/CreditCard';

export function CreditSlider({ id, credits }: CreditSliderProps) {
  const isTablet = useMedia('(max-width: 1259.9px)');

  if (!credits || credits.length === 0) {
    return null;
  }

  const isWithoutNavs = !isTablet ? credits.length === 1 : true;
  const isNoSwiping = credits.length === 1;

  return (
    <SliderWrapper
      id={id}
      options={{
        spaceBetween: 20,
        noSwipingClass: 'swiper-no-swiping',
        withoutNavs: isWithoutNavs,
        autoplay: { delay: 5000, disableOnInteraction: false },
      }}
    >
      {credits.map((credit, index) => (
        <SwiperSlide
          key={index}
          className={isNoSwiping ? 'swiper-no-swiping' : ''}
        >
          <CreditCard {...credit} />
        </SwiperSlide>
      ))}
    </SliderWrapper>
  );
}
