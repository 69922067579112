import React from 'react';
import styled, { css } from 'styled-components';

import Link from '@/components/Link';
import { colors } from '@/constants/theme';

import { BreadcrumbsProps } from './types';

export function Breadcrumbs({ links }: BreadcrumbsProps) {
  if (!links || links.length === 0) {
    return null;
  }

  return (
    <Component>
      {links.map(({ label, link }, index) => (
        <React.Fragment key={index}>
          <LinkItem to={link} currentLink={index === links.length - 1}>
            {label}
          </LinkItem>
          {index + 1 !== links.length && <Slash>/</Slash>}
        </React.Fragment>
      ))}
    </Component>
  );
}

const Component = styled.div`
  display: flex;
  align-items: center;
`;

const LinkItem = styled(Link)<{ currentLink?: boolean }>`
  font-size: 16px;
  line-height: 21px;
  white-space: nowrap;
  color: ${(props) => props.theme.black};

  &:hover {
    color: ${colors.main};
  }

  ${({ currentLink }) =>
    currentLink &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      white-space: initial;
      color: ${colors.gray500};
    `}
`;

const Slash = styled.span`
  font-size: 16px;
  line-height: 21px;
  margin: 0 5px;
  color: ${(props) => props.theme.black};
`;
