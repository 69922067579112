import React from 'react';
import styled, { css } from 'styled-components';

import { DepartmentItem } from '@/components/LandingWidgets/components/DepartmentList/types';
import Link from '@/components/Link';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { getPhoneLink } from '@/utils/common';

import { colors as componentColors } from './constants';

function DepartmentCard({ name, phones, workTimes }: DepartmentItem) {
  return (
    <Component>
      <Name>{name}</Name>
      <Body>
        {phones && phones.length !== 0 && (
          <Item>
            <ItemTitle>Телефоны</ItemTitle>
            <ItemBody>
              {phones.map((phone, index) => (
                <ItemLink key={index} to={getPhoneLink(phone)}>
                  {phone}
                </ItemLink>
              ))}
            </ItemBody>
          </Item>
        )}
        {workTimes && workTimes.length !== 0 && (
          <Item>
            <ItemTitle>Время работы</ItemTitle>
            <ItemBody>
              {workTimes.map((workTime, index) => (
                <ItemText key={index}>{workTime}</ItemText>
              ))}
            </ItemBody>
          </Item>
        )}
      </Body>
    </Component>
  );
}

export default DepartmentCard;

const Component = styled.div``;

const Name = styled.p`
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  min-height: 25.299%;
  color: ${componentColors.primary};

  ${media.tablet(css`
    font-size: 20px;
    line-height: 26px;
    min-height: auto;
  `)}
`;

const Body = styled.div`
  margin-top: 20px;

  ${media.tablet(css`
    margin-top: 10px;
  `)}
`;

const Item = styled.div`
  &:not(:first-child) {
    margin-top: 20px;

    ${media.tablet(css`
      margin-top: 10px;
    `)}
  }
`;

const ItemTitle = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: ${componentColors.primary};
`;

const ItemBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;

  ${media.tablet(css`
    margin-top: 5px;
  `)}
`;

const ItemLink = styled(Link)`
  font-size: 16px;
  line-height: 21px;
  border-bottom: 1px solid transparent;
  color: ${colors.main};

  &:hover {
    color: ${colors.main100};
    border-color: ${colors.main100};
  }
`;

const ItemText = styled.p`
  font-size: 16px;
  line-height: 21px;
  color: ${componentColors.secondary};
`;
