import React from 'react';
import styled, { css } from 'styled-components';

import { colors, getTheme, ThemeValuesType } from '@/constants/theme';
import B2bSvg from '@/assets/svg/dealer-web/b2b-logo.svg';
import HisunSvg from '@/assets/svg/dealer-web/hisun-logo-white.svg';
import JacSvg from '@/assets/svg/dealer-web/jac-logo.svg';
import JetourSvg from '@/assets/svg/dealer-web/jetour-logo.svg';
import Link from '@/components/Link';
import { media } from '@/utils/mixin';
import { getPhoneLink } from '@/utils/common';

import { colors as componentColors } from './constants';
import { DealerCardProps } from './types';
import FacebookIcon from './assets/facebook.svg';
import VKIcon from './assets/vk.svg';
import InstagramIcon from './assets/instagram.svg';
import OKIcon from './assets/ok.svg';
import YoutubeIcon from './assets/youtube.svg';
import TelegramIcon from './assets/tlgrm.svg';
import ViberIcon from './assets/viber.svg';
import SkypeIcon from './assets/skype.svg';

function DealerCard({
  name,
  legalName,
  address,
  services,
  phones,
  socials,
}: DealerCardProps) {
  const theme = getTheme();

  const logo =
    theme === 'b2b' ? (
      <B2bSvg />
    ) : theme === 'hisun' ? (
      <HisunSvg />
    ) : theme === 'jac' ? (
      <JacSvg />
    ) : theme === 'jetour' ? (
      <JetourSvg />
    ) : (
      ''
    );

  return (
    <Component>
      <Header>
        <Name>{name}</Name>
        <LogoWrapper>{logo}</LogoWrapper>
      </Header>

      <LegalName>{legalName}</LegalName>

      <List>
        {address && (
          <Column>
            <ColumnTitle>Адрес</ColumnTitle>
            <ColumnBody>
              <ColumnText>{address}</ColumnText>
            </ColumnBody>
          </Column>
        )}

        {services && services.length !== 0 && (
          <Column>
            <ColumnTitle>Сервисы</ColumnTitle>
            <ColumnBody>
              {services.map((service, index) => (
                <ColumnText key={index}>{service}</ColumnText>
              ))}
            </ColumnBody>
          </Column>
        )}

        {phones && phones.length !== 0 && (
          <Column>
            <ColumnTitle>Телефоны</ColumnTitle>
            <ColumnBody>
              {phones.map((phone, index) => (
                <ColumnLink key={index} to={getPhoneLink(phone)}>
                  {phone}
                </ColumnLink>
              ))}
            </ColumnBody>
          </Column>
        )}

        {socials &&
          (socials.facebook ||
            socials.vk ||
            socials.instagram ||
            socials.ok ||
            socials.youtube ||
            socials.telegram ||
            socials.viber ||
            socials.skype) && (
            <Column>
              <ColumnTitle>Социальные сети</ColumnTitle>
              <ColumnBody>
                <Socials>
                  {socials.facebook && (
                    <Social to={socials.facebook} target="_blank" theme={theme}>
                      <FacebookIcon />
                    </Social>
                  )}
                  {socials.vk && (
                    <Social to={socials.vk} target="_blank" theme={theme}>
                      <VKIcon />
                    </Social>
                  )}
                  {socials.instagram && (
                    <Social
                      to={socials.instagram}
                      target="_blank"
                      theme={theme}
                    >
                      <InstagramIcon />
                    </Social>
                  )}
                  {socials.ok && (
                    <Social to={socials.ok} target="_blank" theme={theme}>
                      <OKIcon />
                    </Social>
                  )}
                  {socials.youtube && (
                    <Social to={socials.youtube} target="_blank" theme={theme}>
                      <YoutubeIcon />
                    </Social>
                  )}
                  {socials.telegram && (
                    <Social to={socials.telegram} target="_blank" theme={theme}>
                      <TelegramIcon />
                    </Social>
                  )}
                  {socials.viber && (
                    <Social to={socials.viber} target="_blank" theme={theme}>
                      <ViberIcon />
                    </Social>
                  )}
                  {socials.skype && (
                    <Social to={socials.skype} target="_blank" theme={theme}>
                      <SkypeIcon />
                    </Social>
                  )}
                </Socials>
              </ColumnBody>
            </Column>
          )}
      </List>
    </Component>
  );
}

export default DealerCard;

const Component = styled.div`
  padding: 30px;
  background: ${colors.white};

  ${media.tablet(css`
    padding: 20px;
  `)}
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Name = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.main};
`;

const LogoWrapper = styled.div`
  margin-left: 20px;

  svg {
    width: 87px;
    height: 25px;
  }
`;

const LegalName = styled.p`
  max-width: 80%;
  font-size: 14px;
  line-height: 14px;
  margin-top: 5px;
  color: ${componentColors.textPrimary};

  ${media.tablet(css`
    max-width: none;
  `)}
`;

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 20px;

  ${media.tablet(css`
    margin-top: 26px;
  `)}

  ${media.mobile(css`
    grid-template-columns: repeat(1, 1fr);
  `)}
`;

const Column = styled.div``;

const ColumnTitle = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: ${componentColors.textSecondary};
`;

const ColumnBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
`;

const ColumnText = styled.p`
  font-size: 14px;
  line-height: 14px;
  color: ${componentColors.textPrimary};

  &:not(:first-child) {
    margin-top: 5px;
  }
`;

const ColumnLink = styled(Link)`
  font-size: 14px;
  line-height: 14px;
  border-bottom: 1px solid transparent;
  color: ${colors.main};

  &:not(:first-child) {
    margin-top: 5px;
  }

  &:hover {
    color: ${colors.main100};
    border-color: ${colors.main100};
  }
`;

const Socials = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Social = styled(Link)<{ theme: ThemeValuesType }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin: 0 10px 10px 0;
  padding: 3px;
  background: ${componentColors.social};

  &:hover {
    background: ${colors.main};
  }

  ${({ theme }) =>
    (theme === 'main' || theme === 'b2b' || theme === 'jac') &&
    css`
      border-radius: 50%;
    `}
`;
