import React, { useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useUpdateEffect } from '@tager/web-core';

import Picture from '@/components/Picture';
import {
  PaginationList,
  PaginationListItem,
} from '@/components/CatalogCarCard/components/CatalogCardSlider/CatalogCardSlider';
import { CatalogCardSliderProps } from '@/components/CatalogCarCard/CatalogCarCard.types';
import Link from '@/components/Link';

function MobileCatalogCardSlider({
  link,
  images,
  alt,
}: CatalogCardSliderProps) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  useUpdateEffect(() => {
    setActiveSlideIndex(0);
  }, [images]);

  return (
    <Component>
      <Swiper
        onSlideChange={(swiper) => setActiveSlideIndex(swiper.activeIndex)}
      >
        {images && images.length > 0
          ? images.slice(0, 4).map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <Slide to={link}>
                    <StyledPicture
                      src={item}
                      loading="lazy"
                      alt={`${alt ?? 'catalog card image'} #${index + 1}`}
                    />
                  </Slide>
                </SwiperSlide>
              );
            })
          : null}
      </Swiper>
      {images.length > 1 && (
        <PaginationList>
          {images.slice(0, 4).map((item: any, index: number) => {
            return (
              <PaginationListItem
                key={index}
                isActive={activeSlideIndex === index}
              />
            );
          })}
        </PaginationList>
      )}
    </Component>
  );
}

export default MobileCatalogCardSlider;

const Component = styled.div`
  position: relative;
  background-color: #efefef;
  border-radius: 8px 8px 0 0;
  padding-top: 60%;
  .swiper-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
`;

const Slide = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const StyledPicture = styled(Picture)`
  position: static !important;
  img,
  picture {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  img {
    object-fit: contain;
  }
`;
