import React, { useState } from 'react';
import { FormikErrors, useFormik } from 'formik';
import styled, { css } from 'styled-components';

import { convertRequestErrorToMap, useUpdateEffect } from '@tager/web-core';
import { convertThumbnailToPictureImage } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import StaticTextInput from '@/components/StaticTextInput';
import { Input as StaticTextInputField } from '@/components/StaticTextInput/StaticTextInput';
import { Error as StaticTextInputError } from '@/components/StaticTextInput/StaticTextInput';
import { handleValidateField, TypesOfValidate } from '@/utils/textInput';
import StaticTextArea from '@/components/StaticTextArea';
import { Area as StaticTextAreaField } from '@/components/StaticTextArea/StaticTextArea';
import { CheckboxAgreement } from '@/components/Checkbox';
import { media } from '@/utils/mixin';
import Loader from '@/components/Loader';
import Button from '@/components/Button';

import { colors as componentColors } from './constants';
import {
  initialServiceFormSecondaryValues,
  validationServiceFormSecondarySchema,
} from './helpers';
import { ServiceFormSecondaryFields, ServiceFormSecondaryProps } from './types';

export function ServiceFormSecondary({
  title,
  subtitle,
  image,
  buttonTitle,
  imageMobile,
  onSubmit,
}: ServiceFormSecondaryProps) {
  const [isFormSend, setFormSend] = useState(false);
  const [isFormCompleted, setFormCompleted] = useState(true);
  const [userAgreement, setUserAgreement] = useState(false);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setErrors,
    isSubmitting,
    resetForm,
  } = useFormik<ServiceFormSecondaryFields>({
    initialValues: initialServiceFormSecondaryValues,
    validationSchema: validationServiceFormSecondarySchema,
    onSubmit: async (values, { setSubmitting }) => {
      if (!userAgreement) {
        return;
      }

      setSubmitting(true);

      try {
        await onSubmit?.(values).then(() => {
          resetForm();
          setUserAgreement(false);
          setFormSend(true);
        });
      } catch (error: any) {
        const errorMap = convertRequestErrorToMap(
          error
        ) as FormikErrors<ServiceFormSecondaryFields>;
        setErrors(errorMap);
        console.log(error);
      }

      setSubmitting(false);
    },
  });

  const onSubmitButtonClick = () => {
    if (!values.name || !values.phone || !userAgreement) {
      setFormCompleted(false);
    }
  };

  useUpdateEffect(() => {
    if (
      isFormSend &&
      (values.name || values.phone || values.message || userAgreement)
    ) {
      setFormSend(false);
    }

    if (!isFormCompleted) {
      setFormCompleted(true);
    }
  }, [values, userAgreement]);

  return (
    <Component>
      <Form onSubmit={handleSubmit} noValidate autoComplete="off">
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>

        <Fields>
          {isFormSend && <SuccessMessage>Заявка отправлена</SuccessMessage>}

          <Input>
            <StaticTextInput
              id="name"
              name="name"
              value={values.name}
              onChange={(event) =>
                handleValidateField(event, handleChange, TypesOfValidate.TEXT)
              }
              placeholder="Имя"
              errorMessage={
                errors.name && touched.name ? errors.name : undefined
              }
              autoComplete="off"
              isWhiteBackground
            />
          </Input>

          <Input>
            <StaticTextInput
              id="phone"
              name="phone"
              value={values.phone}
              onChange={(event) => {
                handleValidateField(event, handleChange, TypesOfValidate.PHONE);
              }}
              placeholder="Телефон"
              errorMessage={
                errors.phone && touched.phone ? errors.phone : undefined
              }
              autoComplete="off"
              isWhiteBackground
            />
          </Input>

          <TextArea>
            <StaticTextArea
              id="message"
              name="message"
              value={values.message}
              onChange={handleChange}
              placeholder="Комментарий"
              errorMessage={
                errors.message && touched.message ? errors.message : undefined
              }
              autoComplete="off"
              isWhiteBackground
            />
          </TextArea>

          <CheckBoxWrapper>
            <CheckboxAgreement
              checked={userAgreement}
              onChange={() => setUserAgreement(!userAgreement)}
            />
          </CheckBoxWrapper>
        </Fields>

        <Bottom>
          <SubmitButton
            type="submit"
            variant="contained"
            onClick={onSubmitButtonClick}
            disabled={isSubmitting}
          >
            {!isSubmitting ? buttonTitle : <Loader color={colors.white} />}
          </SubmitButton>

          {!isFormCompleted && (
            <Error>Заполните, пожалуйста, обязательные поля</Error>
          )}
        </Bottom>
      </Form>
      <Image
        loading="lazy"
        desktop={convertThumbnailToPictureImage(image)}
        laptop={convertThumbnailToPictureImage(image)}
        tabletSmall={convertThumbnailToPictureImage(imageMobile ?? image)}
        mobile={convertThumbnailToPictureImage(imageMobile ?? image)}
        alt={title}
      />
    </Component>
  );
}

const Component = styled.div`
  display: flex;

  ${media.tablet(css`
    flex-direction: column-reverse;
  `)}
`;

const Form = styled.form`
  flex: 1;
  max-width: 100%;
  padding: 60px 125px 60px 60px;
  background: ${componentColors.formBackground};

  ${media.tablet(css`
    flex: auto;
    max-width: none;
    padding: 30px 20px;
  `)}
`;

const Image = styled(Picture)`
  flex: 0 0 500px;
  max-width: 500px;
  min-height: 100%;

  ${media.tablet(css`
    flex: auto;
    max-width: none;
    min-height: auto;
    padding-top: 41.668%;
  `)}

  picture,
  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 35px;
  line-height: 45px;
  text-transform: uppercase;
  color: ${componentColors.text};

  ${media.tablet(css`
    font-size: 22px;
    line-height: 29px;
  `)}
`;

const Subtitle = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin-top: 10px;
  color: ${componentColors.text};
`;

const Fields = styled.div`
  position: relative;
  margin-top: 30px;
`;

const Input = styled.div`
  ${StaticTextInputField} {
    padding: 10px;
    height: 40px;
    font-size: 16px;
    line-height: 21px;
    border-radius: 0;
    color: ${componentColors.text};
    border: 1px solid ${componentColors.field};
  }

  ${StaticTextInputError} {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    bottom: -15px;
    color: ${componentColors.fieldError};
  }

  &:not(:first-child) {
    margin-top: 30px;
  }
`;

const TextArea = styled.div`
  margin-top: 30px;

  ${StaticTextAreaField} {
    padding: 10px;
    height: 120px;
    font-size: 16px;
    line-height: 21px;
    border-radius: 0;
    color: ${componentColors.text};
    border: 1px solid ${componentColors.field};

    ${media.tablet(css`
      height: 70px;
    `)}
  }
`;

const CheckBoxWrapper = styled.div`
  margin-top: 20px;
`;

const Bottom = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;

  ${media.tablet(css`
    flex-direction: column;
    margin-top: 32px;
  `)}
`;

const SubmitButton = styled(Button)`
  max-width: 250px;

  ${media.tablet(css`
    max-width: none;
  `)}
`;

const Error = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  margin-left: 15px;
  color: ${componentColors.fieldError};

  ${media.tablet(css`
    margin-left: 0;
    margin-top: 15px;
  `)}
`;

const SuccessMessage = styled.span`
  position: absolute;
  right: 0;
  top: -20px;
  font-size: 14px;
  line-height: 14px;
  color: ${colors.green};
`;
