import { css } from 'styled-components';

import { media } from '@/utils/mixin';

import { StaticTextInputProps } from './StaticTextInput.types';

export const StaticTextInputStyles = css<{
  invalid: boolean;
  isWhiteBackground: StaticTextInputProps['isWhiteBackground'];
}>`
  display: block;
  width: 100%;
  max-width: 100%;
  height: 62px;
  box-sizing: border-box;
  padding: 21px 15px;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  border-radius: 5px;
  background: ${(props) => props.theme.white_1};
  color: ${(props) => props.theme.black};
  border: 1px solid transparent;
  outline: none;
  transition: 0.3s;

  ${media.laptop(css`
    font-size: 17px;
    line-height: 21px;
    padding: 19px 16px;
    height: 50px;
  `)}

  ${media.tablet(css`
    font-size: 16px;
    line-height: 20px;
    padding: 17px 16px;
    height: 48px;
  `)}

  &:disabled {
    color: ${(props) => props.theme.black100_alpha50};
  }

  ${({ invalid }) =>
    invalid &&
    css`
      border-color: rgba(244, 67, 54, 1);
    `}

  ${({ isWhiteBackground }) =>
    isWhiteBackground &&
    css`
      border: 1px solid ${(props) => props.theme.black100_alpha15_1};
    `}
`;
