import styled, { css } from 'styled-components';

import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';

export const Component = styled.div`
  --slide-height: 50%;
`;

export const SwiperStyles = css`
  .swiper-slide {
    width: 100%;

    @media (min-width: 1260px) {
      max-width: 64.1666%;
    }

    max-width: calc(100% - 40px);

    img {
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &.swiper-slide-active {
    }

    &.swiper-slide-prev,
    &.swiper-slide-next {
      img {
        opacity: 0.5;
        margin-top: 10px;
        height: calc(100% - 20px);

        @media (min-width: 1400px) {
          margin-top: 50px;
          height: calc(100% - 100px);
        }
      }
    }
  }
`;

export const Slide = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding-top: var(--slide-height);
`;

export const SlideImage = styled(Picture)`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  picture,
  img {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
    background-color: ${colors.gray1000};
  }
`;

export const Content = styled.div`
  margin: 15px auto;
  width: 100%;
  max-width: calc(100% - 40px);

  font-weight: 300;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: ${(props) => props.theme.black};
  opacity: 0.5;

  @media (min-width: 1400px) {
    max-width: 922px;

    font-size: 18px;
  }
`;

export const PaginationComponent = styled.div``;

export const ActiveScrollSlide = styled.div``;

export const SwiperScrollbar = styled.div<{ countSlides: number }>`
  position: relative;
  width: 100%;
  max-width: calc(100% - 40px);
  margin: 15px auto 0 auto;

  height: 2px;
  background: ${(props) => props.theme.black_alpha20_1};
  border-radius: 2px;

  @media (min-width: 1260px) {
    max-width: 922px;
  }

  ${ActiveScrollSlide} {
    height: 100%;
    width: calc(100% / ${(props) => props.countSlides});
    background: ${(props) => props.theme.black};
    transition: transform 0.7s ease;
  }

  ${PaginationComponent} {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;

    .swiper-pagination-bullet {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      width: calc(100% / ${(props) => props.countSlides}) !important;
      height: 2px;
      opacity: 0;
      transition: opacity 0.4s ease;

      &:hover {
        cursor: pointer;
        opacity: 0.3;
      }
    }
  }
`;

export const SwiperNavigation = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  z-index: 1;

  margin: 0 auto;
  width: 100%;

  @media (min-width: 1260px) {
    max-width: 64.1666%;
  }
`;

export const SwiperPrev = styled.span`
  position: absolute;
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
  z-index: 100;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    left: 20px;
    transform: translateY(-50%) scale(0.75);
  }
`;

export const SwiperNext = styled.span`
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
  z-index: 100;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    right: 20px;
    transform: translateY(-50%) scale(0.75);
  }
`;
