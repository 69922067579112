import { getTheme } from '@/constants/theme';

import { greyMapStyles } from './constants';

export const getMapStyles = () => {
  const theme = getTheme();

  switch (theme) {
    case 'hisun':
      return greyMapStyles;
    case 'jac':
      return greyMapStyles;
    case 'jetour':
      return greyMapStyles;
    default:
      return undefined;
  }
};
