import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Tippy from '@tippyjs/react';

import { formatNumber, isNotNullish } from '@tager/web-core';

import { ComponentViewProps } from '@/components/CatalogCarCard/CatalogCarCard.types';
import CatalogCardSlider from '@/components/CatalogCarCard/components/CatalogCardSlider';
import {
  CardTitle,
  ShareWrapper,
  AvailableColors,
  AvailableColorsItem,
  TitleWrapper,
  MinPrice,
  Price,
  Info,
  PriceInfo,
  InfoIconWrapper,
} from '@/components/CatalogCarCard/components/CarPortraitView/CarPortraitView';
import ArrowRightIcon from '@/assets/svg/more_details_arrow_right.svg';
import Share from '@/components/Share';
import Link from '@/components/Link';
import InfoIcon from '@/assets/svg/info.svg';
import { colors } from '@/constants/theme';
import Button, { ButtonNextLink } from '@/components/Button';

function CarLandscapeView({
  view,
  link,
  name,
  button,
  images,
  price,
  priceInfo,
}: ComponentViewProps) {
  const [currentColor, setCurrentColor] = useState(images[0]?.color);

  const carImages =
    images.find((image) => image?.color?.value === currentColor?.value)
      ?.items ?? (images && images.length > 0 ? images[0].items : []);

  const colors =
    images && images.length > 0
      ? images.map((image) => image?.color).filter(isNotNullish)
      : [];

  return (
    <Component>
      <CardSlider>
        <CatalogCardSlider
          images={carImages}
          view={view}
          link={link.url}
          alt={`${name} ${currentColor?.label ?? ''}`.trim()}
        />
      </CardSlider>
      <CardInfo>
        <TitleWrapper>
          <CardTitle to={link.url}>{name}</CardTitle>
        </TitleWrapper>
        <AvailableColors>
          {colors &&
            colors.length > 0 &&
            colors.map((item, index) => (
              <AvailableColorsItem
                key={index}
                item={item.value}
                isActive={item.value === currentColor?.value}
                onClick={() => setCurrentColor(item)}
              />
            ))}
        </AvailableColors>
        {price && (
          <MinPrice>
            <Price>
              ОТ <span>{formatNumber(price)}</span> BYN
            </Price>

            {priceInfo && (
              <Info>
                <Tippy
                  content={<PriceInfo>{priceInfo}</PriceInfo>}
                  placement="bottom"
                >
                  <InfoIconWrapper>
                    <InfoIcon />
                  </InfoIconWrapper>
                </Tippy>
              </Info>
            )}
          </MinPrice>
        )}
        <AboutModel to={link.url}>
          О модели
          <Icon>
            <ArrowRightIcon />
          </Icon>
        </AboutModel>
        {button.onClick ? (
          <StyledButton variant="contained" onClick={button.onClick}>
            {button.label}
          </StyledButton>
        ) : (
          <StyledButtonLink variant="contained" to={button.url}>
            {button.label}
          </StyledButtonLink>
        )}
        <ShareWrapper className="disabled-modal">
          <Share path={link.url} image={carImages?.[0]} />
        </ShareWrapper>
      </CardInfo>
    </Component>
  );
}

export default CarLandscapeView;

const Component = styled.div`
  display: flex;
  width: 100%;
`;

const CardInfo = styled.div`
  position: relative;
  flex: 0 1 67%;
  background: ${(props) => props.theme.white_1};
  padding: 20px;
  border-radius: 0 8px 8px 0;
`;

const CardSlider = styled.div`
  width: 100%;
  height: 100%;
  flex: 0 1 33%;
`;

const AboutModel = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  cursor: pointer;
  color: ${(props) => props.theme.black};

  &:hover {
    color: ${colors.main100};

    & div > svg {
      fill: ${colors.main100};
    }
  }
`;

const Icon = styled.div`
  margin-left: 5px;
  cursor: pointer;

  svg {
    fill: ${(props) => props.theme.black100};
  }
`;

const ButtonStyles = css`
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 14px 0;
  font-weight: bold;
  width: 25%;
`;

const StyledButton = styled(Button)`
  ${ButtonStyles}
`;

const StyledButtonLink = styled(ButtonNextLink)`
  ${ButtonStyles}
`;
