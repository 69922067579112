import React from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import Button from '@/components/Button';

import { SelectionCarCardProps } from './types';

function SelectionCarCard({
  image,
  title,
  description,
  buttonTitle,
  onClick,
}: SelectionCarCardProps) {
  return (
    <Component>
      <Image
        src={image?.url}
        src2x={image?.url_2x}
        srcWebp={image?.url_webp}
        srcWebp2x={image?.url_webp_2x}
        alt={title}
      />
      <Content>
        <Header>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </Header>

        <StyledButton variant="contained" onClick={onClick}>
          {buttonTitle}
        </StyledButton>
      </Content>
    </Component>
  );
}

export default SelectionCarCard;

export const Component = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.gray800};
  border-radius: 8px;
`;

const Image = styled(Picture)`
  padding-top: 60%;

  picture,
  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px 8px 0 0;
  }

  img {
    object-fit: cover;
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const Header = styled.div``;

const Title = styled.p`
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin-top: 15px;
  color: ${colors.blue600};
`;

const StyledButton = styled(Button)`
  font-weight: bold;
  margin: 18px 0 50px;
`;
