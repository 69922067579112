import { getTheme } from '@/constants/theme';

const colorsMain = {
  address: '#6c7075',
} as const;

const colorsB2b = {
  address: '#6c7075',
} as const;

const colorsHisun = {
  address: '#5c5c5c',
} as const;

const colorsJac = {
  address: '#636466',
} as const;

const colorsJetour = {
  address: '#4c4c4c',
} as const;

export const colors = (() => {
  switch (getTheme()) {
    case 'b2b':
      return colorsB2b;
    case 'hisun':
      return colorsHisun;
    case 'jac':
      return colorsJac;
    case 'jetour':
      return colorsJetour;
    default:
      return colorsMain;
  }
})();
