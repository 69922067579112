import { getTheme } from '@/constants/theme';

const colorsMain = {
  paginationActiveBackground: '#0061ed',
  paginationActiveBorders: 'transparent',
  paginationDisactiveBackground: '#d3dadf',
  navActiveBackground: 'transparent',
  navDisactiveBackground: 'transparent',
  navActiveBorders: '#d0d5dc',
  navDisactiveBorders: '#d0d5dc',
  navActiveIcon: '#0061ed',
  navDisactiveIcon: '#dcdcdc',
} as const;

const colorsB2b = {
  paginationActiveBackground: '#0066b3',
  paginationActiveBorders: 'transparent',
  paginationDisactiveBackground: '#d3dadf',
  navActiveBackground: 'transparent',
  navDisactiveBackground: 'transparent',
  navActiveBorders: '#d3dadf',
  navDisactiveBorders: '#d3dadf',
  navActiveIcon: '#0066b3',
  navDisactiveIcon: '#d3dadf',
} as const;

const colorsHisun = {
  paginationActiveBackground: 'transparent',
  paginationActiveBorders: '#670b22',
  paginationDisactiveBackground: '#333',
  navActiveBackground: '#670b22',
  navDisactiveBackground: 'transparent',
  navActiveBorders: 'transparent',
  navDisactiveBorders: '#d6d6d6',
  navActiveIcon: '#fff',
  navDisactiveIcon: '#d6d6d6',
} as const;

const colorsJac = {
  paginationActiveBackground: '#cc000c',
  paginationActiveBorders: 'transparent',
  paginationDisactiveBackground: '#d7d7d7',
  navActiveBackground: 'transparent',
  navDisactiveBackground: 'transparent',
  navActiveBorders: '#d7d7d7',
  navDisactiveBorders: '#d7d7d7',
  navActiveIcon: '#cc000c',
  navDisactiveIcon: '#d7d7d7',
} as const;

const colorsJetour = {
  paginationActiveBackground: 'transparent',
  paginationActiveBorders: '#ff874a',
  paginationDisactiveBackground: '#ff874a',
  navActiveBackground: 'transparent',
  navDisactiveBackground: 'transparent',
  navActiveBorders: '#ccc',
  navDisactiveBorders: '#ccc',
  navActiveIcon: '#ff874a',
  navDisactiveIcon: '#ccc',
} as const;

export const colors = (() => {
  switch (getTheme()) {
    case 'b2b':
      return colorsB2b;
    case 'hisun':
      return colorsHisun;
    case 'jac':
      return colorsJac;
    case 'jetour':
      return colorsJetour;
    default:
      return colorsMain;
  }
})();
