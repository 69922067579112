import { sum } from 'lodash';

import { defaultMapProps } from './constants';

export const getMapProps = (
  points: { latitude: number; longitude: number }[]
) => {
  const pointsLength = points.length;
  const averageLatitude =
    sum(points.map(({ latitude }) => latitude)) / pointsLength;
  const averageLongitude =
    sum(points.map(({ longitude }) => longitude)) / pointsLength;

  if (averageLatitude && averageLongitude) {
    return {
      desktop: {
        center: {
          lat: averageLatitude,
          lng: averageLongitude - 0.4,
        },
        zoom: 10,
      },
      mobile: {
        center: {
          lat: averageLatitude,
          lng: averageLongitude,
        },
        zoom: 10,
      },
    };
  } else {
    return defaultMapProps;
  }
};
