import React from 'react';
import styled from 'styled-components';

import Picture from '@/components/Picture';
import { colors } from '@/components/LandingWidgets/components/AboutCardSecondary/constants';

import { AboutCardSecondaryBenefitProps } from './types';

export function AboutCardSecondaryBenefit({
  image,
  title,
  description,
}: AboutCardSecondaryBenefitProps) {
  return (
    <Component>
      <Image
        loading="lazy"
        src={image?.url}
        src2x={image?.url_2x}
        srcWebp={image?.url_webp}
        srcWebp2x={image?.url_webp_2x}
        alt={title}
      />
      <Title>{title}</Title>
      {description && (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      )}
    </Component>
  );
}

export const Component = styled.div`
  padding: 30px 20px;
  background: ${colors.background};
  border: 1px solid ${colors.borders};
`;

const Image = styled(Picture)`
  width: 120px;
  height: 120px;
  margin: 0 auto;

  picture,
  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  margin-top: 20px;
  color: ${colors.title};
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  margin-top: 10px;
  color: ${colors.text};
`;
