import { getTheme } from '@/constants/theme';

const colorsMain = {
  text: '#000',
  field: '#d3dadf',
  fieldError: '#ed0000',
} as const;

const colorsB2b = {
  text: '#182028',
  field: '#d3dadf',
  fieldError: '#ed0000',
} as const;

const colorsHisun = {
  text: '#333',
  field: '#d6d6d6',
  fieldError: '#ec1b23',
} as const;

const colorsJac = {
  text: '#1d1d1b',
  field: '#d7d7d7',
  fieldError: '#ed0000',
} as const;

const colorsJetour = {
  text: '#000',
  field: '#4c4c4c',
  fieldError: '#d20100',
} as const;

export const colors = (() => {
  switch (getTheme()) {
    case 'b2b':
      return colorsB2b;
    case 'hisun':
      return colorsHisun;
    case 'jac':
      return colorsJac;
    case 'jetour':
      return colorsJetour;
    default:
      return colorsMain;
  }
})();
