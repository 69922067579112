import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';
import { ButtonNextLink } from '@/components/Button';
import { colors } from '@/constants/theme';

import { ShowMoreCardProps } from './types';

function ShowMoreCard({ image, buttonTitle, buttonLink }: ShowMoreCardProps) {
  return (
    <Component>
      <Image
        loading="lazy"
        src={image?.url}
        src2x={image?.url_2x}
        srcWebp={image?.url_webp}
        srcWebp2x={image?.url_webp_2x}
        alt="show more image"
      />
      <ButtonWrapper>
        <StyledButtonLink variant="contained" to={buttonLink}>
          {buttonTitle}
        </StyledButtonLink>
      </ButtonWrapper>
    </Component>
  );
}

export default ShowMoreCard;

const Component = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-radius: 8px;
  padding: 20px;
  background: ${colors.gray100};

  ${media.laptop(css`
    padding: 17px 15px;
  `)}

  ${media.tablet(css`
    padding: 12px;
  `)}
`;

const Image = styled(Picture)`
  width: 100%;
  padding-top: 110%;

  ${media.laptop(css`
    padding-top: 133%;
  `)}

  ${media.tablet(css`
    padding-top: 90%;
  `)}

  ${media.tabletSmall(css`
    padding-top: 80%;
  `)}

  ${media.mobile(css`
    padding-top: 70%;
  `)};

  @media (max-width: 500px) {
    padding-top: 90%;
  }

  picture,
  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 46px;

  ${media.laptop(css`
    margin-bottom: 39px;
  `)}

  ${media.tablet(css`
    margin-bottom: 32px;
  `)}
`;

const StyledButtonLink = styled(ButtonNextLink)`
  width: 100%;
  height: 52px;
  padding: 13px 0;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  text-transform: uppercase;
  text-align: center;
  border-radius: 20px;

  ${media.laptop(css`
    height: 40px;
    padding: 10px 0;
    font-size: 17px;
    line-height: 20px;
  `)}

  ${media.tablet(css`
    height: 32px;
    padding: 8px 0;
    font-size: 14px;
    line-height: 16px;
  `)}
`;
