import { getTheme } from '@/constants/theme';

const colorsMain = {
  text: '#000',
  selectedText: '#0061ed',
  background: '#f3f4f5',
  borders: 'transparent',
  line: 'transparent',
} as const;

const colorsB2b = {
  text: '#182028',
  selectedText: '#0066b3',
  background: 'rgba(211, 218, 223, 0.3)',
  borders: 'transparent',
  line: 'transparent',
} as const;

const colorsHisun = {
  text: '#333',
  selectedText: '#670b22',
  background: '#fff',
  borders: '#d6d6d6',
  line: 'transparent',
} as const;

const colorsJac = {
  text: '#1d1d1b',
  selectedText: '#cc000c',
  background: '#fff',
  borders: 'transparent',
  line: '#CC000C',
} as const;

const colorsJetour = {
  text: '#fff',
  selectedText: '#fff',
  background: '#628788',
  borders: 'transparent',
  line: 'transparent',
} as const;

export const colors = (() => {
  switch (getTheme()) {
    case 'b2b':
      return colorsB2b;
    case 'hisun':
      return colorsHisun;
    case 'jac':
      return colorsJac;
    case 'jetour':
      return colorsJetour;
    default:
      return colorsMain;
  }
})();
