import React from 'react';
import styled from 'styled-components';

import { AboutCardPrimaryBenefitType } from '@/components/LandingWidgets/components/AboutCardPrimary/types';
import Picture from '@/components/Picture';
import { colors } from '@/components/LandingWidgets/components/AboutCardPrimary/constants';

function AboutCardPrimaryBenefit({ image, text }: AboutCardPrimaryBenefitType) {
  return (
    <Component>
      <Image
        loading="lazy"
        src={image?.url}
        src2x={image?.url_2x}
        srcWebp={image?.url_webp}
        srcWebp2x={image?.url_webp_2x}
        alt={text}
      />
      <Text>{text}</Text>
    </Component>
  );
}

export default AboutCardPrimaryBenefit;

export const Component = styled.div`
  display: flex;
  flex-direction: column;
`;

const Image = styled(Picture)`
  picture,
  img {
    width: 70px;
    height: 70px;
  }
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  margin-top: 20px;
  color: ${colors.text};
`;
