import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';
import CloseButton from '@/components/modals/shared/CloseButton';

import { ImagesSliderModalProps } from './ImagesSliderModal.types';

function ImagesSliderModal({ innerProps, closeModal }: ImagesSliderModalProps) {
  const { slides, activeSlideIndex } = innerProps;

  useLockBodyScroll();

  return (
    <Component>
      <CloseButton onClick={closeModal} />

      <Swiper
        initialSlide={Number(activeSlideIndex)}
        loop={true}
        slideToClickedSlide={true}
        breakpoints={{
          320: {
            direction: 'vertical',
            centeredSlides: true,
            slidesPerView: 3,
            spaceBetween: 10,
          },

          768: {
            direction: 'horizontal',
            slidesPerView: 1,
            spaceBetween: 0,
          },
        }}
      >
        {slides.map(({ image }, index) => (
          <SwiperSlide key={index}>
            <Image
              loading="lazy"
              src={image.url}
              src2x={image.url_2x}
              srcWebp={image.url_webp}
              srcWebp2x={image.url_webp_2x}
            />
            <SlideCounter>
              {index + 1}/{slides.length}
            </SlideCounter>
          </SwiperSlide>
        ))}
      </Swiper>
    </Component>
  );
}

export default ImagesSliderModal;

const SlideCounter = styled.div`
  position: absolute;
  display: inline-flex;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  left: 2px;
  bottom: 2px;
  padding: 1.5px 8px;
  border-radius: 4px;
  background: rgba(21, 27, 38, 0.8);
  color: ${colors.gray200};
`;

const Component = styled.div`
  position: relative;
  width: calc(100vw - 20px);
  height: calc(100vh - 40px);

  .swiper-container {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    &:before {
      z-index: 2;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        0deg,
        rgba(21, 27, 38, 0.6),
        rgba(21, 27, 38, 0.6)
      );
    }

    ${SlideCounter} {
      display: none;
    }

    &-active {
      &:before {
        display: none;
      }

      ${SlideCounter} {
        display: inline-flex;
      }
    }
  }
`;

const Image = styled(Picture)`
  position: absolute;
  width: 100%;
  height: 100%;

  picture,
  img {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;
