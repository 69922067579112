import React from 'react';
import styled, { css } from 'styled-components';

import Link from '@/components/Link';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import { SwitchLinksProps } from './types';

export function SwitchLinks({ options, checked }: SwitchLinksProps) {
  return (
    <Component>
      <LinkItem to={options.from.value} checked={!checked}>
        {options.from.label}
      </LinkItem>
      <LinkItem to={options.to.value} checked={checked}>
        {options.to.label}
      </LinkItem>
    </Component>
  );
}

const Component = styled.div`
  display: flex;
`;

const LinkItem = styled(Link)<{ checked?: boolean }>`
  flex: 0 0 50%;
  width: 50%;
  padding: 17px 0;
  display: flex;
  justify-content: center;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: ${colors.black};
  background: ${colors.gray200};

  ${media.tablet(css`
    padding: 12px 0;
    font-size: 16px;
    line-height: 21px;
  `)}

  &:first-child {
    border-radius: 20px 0 0 20px;
  }

  &:last-child {
    border-radius: 0 20px 20px 0;
  }

  &:hover {
    opacity: 0.7;
  }

  ${({ checked }) =>
    checked &&
    css`
      font-weight: 600;
      color: ${colors.white};
      background: ${colors.main};
      pointer-events: none;
    `}
`;
