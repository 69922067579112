import React, { useMemo, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Scrollbar, A11y, Navigation, Pagination } from 'swiper';
import styled from 'styled-components';

import { Nullable, useUpdateEffect } from '@tager/web-core';

import ArrowLeftIcon from '@/assets/svg/arrow-left-40px.svg';
import ArrowRightIcon from '@/assets/svg/arrow-right-40px.svg';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';

import ImagesSliderModal from './components/ImagesSliderModal';
import { ImagesSliderProps } from './ImagesSlider.types';
import {
  ActiveScrollSlide,
  Component,
  Content,
  PaginationComponent,
  Slide,
  SlideImage,
  SwiperNavigation,
  SwiperNext,
  SwiperPrev,
  SwiperScrollbar,
  SwiperStyles,
} from './ImagesSlider.styles';

SwiperCore.use([Scrollbar, A11y, Navigation, Pagination]);

function ImagesSlider({ slides, config }: ImagesSliderProps) {
  const [activeIndex, getActiveIndex] = useState<number>(0);
  const activeScrollSlideRef = useRef<HTMLDivElement>(null);
  const paginationRef = useRef<HTMLDivElement>(null);

  const content = useMemo<Nullable<string>>(
    () => slides?.[activeIndex]?.caption ?? null,
    [activeIndex, slides]
  );

  const handleImagesSliderModal = (activeSlideIndex: number) => {
    if (!config?.withModalSlides) {
      return;
    }

    config.openModal?.(
      ImagesSliderModal,
      {
        slides,
        activeSlideIndex,
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  };

  useUpdateEffect(() => {
    const activeScrollSlide = activeScrollSlideRef.current;

    if (activeScrollSlide) {
      const currentWidth = activeScrollSlide.getBoundingClientRect().width;
      const newOffsetLeft = currentWidth * activeIndex;
      activeScrollSlide.style.transform = `translateX(${newOffsetLeft}px)`;
    }
  }, [activeIndex]);

  if (!slides || slides.length === 0) {
    return null;
  }

  return (
    <Component>
      <StyledSwiper
        navigation={{
          prevEl: `.swiper-prev`,
          nextEl: `.swiper-next`,
        }}
        slidesPerView={'auto'}
        centeredSlides={true}
        grabCursor={true}
        spaceBetween={45}
        pagination={{
          el: '.overview-content-slider-pagination',
          type: 'bullets',
          clickable: true,
        }}
        loop={true}
        onActiveIndexChange={({ realIndex }) => {
          getActiveIndex(realIndex);
        }}
        slideToClickedSlide={true}
      >
        {slides.map((slide, index) => (
          <SwiperSlide
            key={index}
            onClick={() => handleImagesSliderModal(index)}
          >
            <Slide>
              <SlideImage
                src={slide.image?.url}
                src2x={slide.image?.url_2x}
                srcWebp={slide.image?.url_webp}
                srcWebp2x={slide.image?.url_webp_2x}
                alt={slide.caption ?? `slide image #${index + 1}`}
                loading="lazy"
              />
            </Slide>
          </SwiperSlide>
        ))}

        <SwiperNavigation>
          <SwiperPrev className="swiper-prev">
            <ArrowLeftIcon />
          </SwiperPrev>
          <SwiperNext className="swiper-next">
            <ArrowRightIcon />
          </SwiperNext>
        </SwiperNavigation>
      </StyledSwiper>

      {content && <Content>{content}</Content>}
      <SwiperScrollbar countSlides={slides.length}>
        <ActiveScrollSlide ref={activeScrollSlideRef} />
        <PaginationComponent
          className="overview-content-slider-pagination"
          ref={paginationRef}
        />
      </SwiperScrollbar>
    </Component>
  );
}

export default ImagesSlider;

const StyledSwiper = styled(Swiper)`
  ${SwiperStyles}
`;
