import React from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

import { useMedia } from '@tager/web-core';

import { colors } from '@/constants/theme';

import { getMapProps } from '../../helpers';

import { YandexMapProps } from './types';

function YandexMap({ markers }: YandexMapProps) {
  const isTablet = useMedia('(max-width: 1259px)');

  if (!markers || markers.length === 0) {
    return null;
  }

  const points = markers.map(({ latitude, longitude }) => ({
    latitude,
    longitude,
  }));
  const mapProps = getMapProps(points);

  return (
    <YMaps
      query={{
        apikey: process.env.NEXT_PUBLIC_API_KEY_YANDEX_MAP,
      }}
    >
      <Map
        style={{ width: '100%', height: '100%' }}
        defaultState={{
          center: !isTablet
            ? [mapProps.desktop.center.lat, mapProps.desktop.center.lng]
            : [mapProps.mobile.center.lat, mapProps.mobile.center.lng],
          zoom: !isTablet ? mapProps.desktop.zoom : mapProps.mobile.zoom,
        }}
        modules={['geoObject.addon.balloon']}
      >
        {markers.map(({ latitude, longitude, name, address }, index) => (
          <Placemark
            key={index}
            geometry={[latitude, longitude]}
            properties={{
              balloonContent:
                '<div>' +
                `<b>${name}</b><br />` +
                `<p>${address}</p>` +
                '</div>',
            }}
            options={{ iconColor: colors.main }}
          />
        ))}
      </Map>
    </YMaps>
  );
}

export default YandexMap;
