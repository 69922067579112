import { getTheme } from '@/constants/theme';

const colorsMain = {
  text: '#000',
  icon: '#0061ed',
} as const;

const colorsB2b = {
  text: '#000',
  icon: '#0066b3',
} as const;

const colorsHisun = {
  text: '#000',
  icon: '#670b22',
} as const;

const colorsJac = {
  text: '#000',
  icon: '#cc000c',
} as const;

const colorsJetour = {
  text: '#000',
  icon: '#ff874a',
} as const;

export const colors = (() => {
  switch (getTheme()) {
    case 'b2b':
      return colorsB2b;
    case 'hisun':
      return colorsHisun;
    case 'jac':
      return colorsJac;
    case 'jetour':
      return colorsJetour;
    default:
      return colorsMain;
  }
})();
