import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

import { VideoBannerProps } from './types';

export function VideoBanner({ video }: VideoBannerProps) {
  return (
    <Component>
      <Container>
        <Video src={video} autoPlay muted loop playsInline />
      </Container>
    </Component>
  );
}

const Component = styled.div`
  width: 100%;
  max-width: 1840px;
  margin: 0 auto;
`;

const Container = styled.div`
  position: relative;
  padding-top: 29.033%;

  ${media.tablet(css`
    padding-top: 41.668%;
  `)}
`;

const Video = styled.video`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
