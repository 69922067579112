import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';

import { HeroWithoutContentProps } from './types';

function HeroWithoutContent({
  desktopImage,
  tabletImage,
  mobileImage,
  alt,
  onlineShopping,
  insurance,
  holding,
  singlePost,
}: HeroWithoutContentProps) {
  return (
    <Component
      onlineShopping={onlineShopping}
      insurance={insurance}
      holding={holding}
      singlePost={singlePost}
    >
      <Background
        loading="lazy"
        desktop={convertThumbnailToPictureImage(desktopImage)}
        laptop={convertThumbnailToPictureImage(desktopImage)}
        tabletSmall={convertThumbnailToPictureImage(
          tabletImage ?? desktopImage
        )}
        mobileSmall={convertThumbnailToPictureImage(
          mobileImage ?? tabletImage ?? desktopImage
        )}
        alt={alt}
      />
    </Component>
  );
}

export default HeroWithoutContent;

const Component = styled.section<{
  onlineShopping: HeroWithoutContentProps['onlineShopping'];
  insurance: HeroWithoutContentProps['insurance'];
  holding: HeroWithoutContentProps['holding'];
  singlePost: HeroWithoutContentProps['singlePost'];
}>`
  position: relative;
  padding-top: 22.909%;

  ${media.tablet(css`
    padding-top: 40%;
  `)}

  ${media.mobile(css`
    padding-top: 58.7%;
  `)}

  ${({ onlineShopping }) =>
    onlineShopping &&
    css`
      padding-top: 28.125%;

      @media (max-width: 1799.9px) {
        padding-top: 34.05%;
      }

      ${media.tablet(css`
        padding-top: 55.1%;
      `)}

      ${media.mobile(css`
        padding-top: 92.9%;
      `)}
    `}

  ${({ insurance }) =>
    insurance &&
    css`
      padding-top: 28.65%;

      ${media.tablet(css`
        padding-top: 40.8%;
      `)}

      ${media.mobile(css`
        padding-top: 86.1%;
      `)}
    `}

  ${({ holding }) =>
    holding &&
    css`
      padding-top: 29.15%;

      ${media.tablet(css`
        padding-top: 40.8%;
      `)}

      ${media.mobile(css`
        padding-top: 88%;
      `)}
    `}

  ${({ singlePost }) =>
    singlePost &&
    css`
      padding-top: 28.1%;

      ${media.tablet(css`
        padding-top: 40.8%;
      `)}

      ${media.mobile(css`
        padding-top: 88%;
      `)}
    `}
`;

const Background = styled(Picture)`
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  img,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;
